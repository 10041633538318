import { REQUEST_GET_PORTFOLIO, FAILURE_GET_PORTFOLIO, SUCCESS_GET_PORTFOLIO } from "../actionTypes";

const initialState = {
    currentShopId: null,
    items: [],
    isLoading: false,
    nextPageCnt: 0,
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_GET_PORTFOLIO: {
            const isShopDiff = payload.shopid !== state.currentShopId;

            if (isShopDiff) {
                return {
                    ...state,
                    items: [],
                    isLoading: true,
                    error: null
                };
            }

            return {
                ...state,
                isLoading: true,
                error: null
            };
        }

        case FAILURE_GET_PORTFOLIO: {
            return {
                ...state,
                isLoading: false,
                error: payload
            };
        }

        case SUCCESS_GET_PORTFOLIO: {
            return {
                ...state,
                isLoading: false,
                currentShopId: payload.currentShopId,
                nextPageCnt: payload.nextPageCnt,
                items: payload.portfolio,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
