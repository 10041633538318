import React from "react";
import PropTypes from "prop-types";
import Box from "ui-box";
import useTheme from "../theme";

function Paragraph(props) {
    const { children, size, color, ...restProps } = props;
    const theme = useTheme();

    return (
        <Box is={"p"} fontSize={theme.size[size]} color={theme.color[color] || color || "inherit"} {...restProps}>
            {children}
        </Box>
    );
}

Paragraph.propTypes = {
    children: PropTypes.node,
    size: PropTypes.string,
    color: PropTypes.string
};

Paragraph.defaultProps = {
    children: "",
    size: "normal",
    color: "gray2"
};

export default Paragraph;
