import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { identity } from "ramda";
import Text from "../Text";
import css from "./textfield.css";

function TextField({ type, name, value, placeholder, children, readOnly, onChange, isRequired }) {
    return (
        <div className={cx(css.textfield)}>
            {children && (
                <Text size={"small"} color={"gray"} className={css.textfieldLabel}>
                    {children}
                    {isRequired && "*"}
                </Text>
            )}
            <input
                type={type}
                name={name}
                value={value}
                placeholder={placeholder}
                className={cx(css.textfieldInput)}
                readOnly={readOnly}
                onChange={onChange}
            />
        </div>
    );
}

TextField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    children: PropTypes.node,
    readOnly: PropTypes.bool,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func
};

TextField.defaultProps = {
    type: "text",
    name: "",
    value: "",
    placeholder: "",
    children: "",
    readOnly: false,
    isRequired: false,
    onChange: identity
};

export default TextField;
