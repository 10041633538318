import qs from "querystring";
import { compose } from "ramda";
import { generateFormData } from "~/utils";
import { get, post, upload, external } from "./crud";
import * as ep from "./endpoint";

export function getTermsService() {
    return external({ baseURL: "mobile_webview" })({ url: "/term_v2" });
}

export function getPrivacyPolicy() {
    return external({ baseURL: "mobile_webview" })({ url: "/privacy_term_v2" });
}

export function get3rdParty() {
    return external({ baseURL: "mobile_webview" })({ url: "/third_party_v2" });
}

export function createUser(userkey) {
    return post(ep.POST_USERS, {
        carbrandname: "",
        cardesc: "",
        pushtoken: "",
        termyn: "N",
        agreelocyn: "N",
        userkey
    });
}

export function getBrandList(query = {}) {
    return get({
        url: ep.GET_BRAND_LIST,
        query: { with: "top10", ...query }
    });
}

export function getAddressList({ keyword, limit }) {
    return get(`${ep.GET_ADDRESS_BY_WORD}/${keyword}/size/${limit}`);
}

// NOTE
// | upload single file
// | key name is `files`
// | API do not support for uploading multiple file
export function uploadImages({ uploadtype = "estreq-web", files, userkey }) {
    return compose(
        upload,
        generateFormData
    )({
        uploadtype,
        userkey,
        files: files.name,
        "files[]": [files, files.name]
    });
}

export function submitUserLocation({ svcType = "repair", lat, lon, userkey }) {
    return post(ep.POST_USER_LOCATIONS, {
        svc_type: svcType,
        long: lon,
        lat,
        userkey
    });
}

export function getCarInsMetadata({ promocode = "", accReqNoEnc = "", accReqNo = "" }) {
    // TODO
    // | it works, if everything is ok
    // | but have potential issue
    // | could be has key but no value
    const pathname = qs.stringify(
        {
            [accReqNoEnc ? "accReqNoEnc" : "accReqNo"]: accReqNoEnc || accReqNo
        },
        null,
        "/"
    );

    if (!promocode) {
        throw new Error("promocode is required");
    }

    return get(`${ep.GET_CAR_INSURANCE_METADATA}/${promocode}/${pathname}`);
}

export function checkValidPromoCode({ promocode = "" }) {
    return get(`${ep.GET_CAR_INSURANCE_METADATA}/${promocode}`);
}

export function submitEstForm({ userkey, vinEnc, form }) {
    return post(ep.POST_ESTIMATE_REQUESTS, {
        insurance: "",
        cashyn: "N",
        rentcaryn: form.reqRentCar ? "Y" : "N",
        pickupyn: form.reqPickup ? "Y" : "N",
        fileid: form.carImageIds,
        etcdesc: form.carImageDesc,
        cardesc: `${form.car} / ${form.year}`,
        accReqNo: form.accReqNo,
        platenum: form.plateNo,
        insu_owner: form.ownerName,
        phonenumber: form.phoneNo,
        carbrandname: form.carBrand,
        promotioncode: form.promotioncode,
        user_location_ids: form.locationIds,
        vinEnc,
        userkey
    });
}

export function getSentEstMetadata(reqid) {
    return get(`${ep.GET_ESTIMATE_METADATA}/${reqid}`);
}

export function getEstByShop(reqid) {
    return get(`${ep.GET_ESTIMATE_LIST}/${reqid}?empty=Y`);
}

export function getShopEst(estid) {
    return get(`${ep.GET_SHOP_EST}/${estid}`);
}

export function getShop(shopid) {
    return get(`${ep.GET_SHOP}/${shopid}`);
}

export function getReviews({ shopid, pageCnt }) {
    return get(`${ep.GET_REVIEWS}/${shopid}/page/${pageCnt}?empty=Y`);
}

export function getPortfolio({ shopid, pageCnt }) {
    return get(`${ep.GET_PORTFOLIO}/${shopid}/page/${pageCnt}?empty=Y`);
}
