import { connect } from "react-redux";
import { BrandList } from "~/pages";
import { saveBrandName, getCarBrandList } from "~/store/actions";

function mapStateToProps({ assets: { topList, byLetter } }) {
    return {
        topList,
        byLetter
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCarBrandList: () => dispatch(getCarBrandList()),
        handleSelectBrandName: brand => dispatch(saveBrandName(brand))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandList);
