import React from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import cx from "classnames";
import { identity } from "ramda";
import css from "./float-contents.css";
import Screen from "../Screen";
import NavBar from "../NavBar";

function FloatingContent({ title, content, onClose }) {
    return (
        <div className={cx(css.floatContents)}>
            <NavBar toolbar={{ title, left: "close" }} onClickLeft={onClose} dark />

            <Screen hasNavi>
                {/* eslint-disable */}
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
                {/* eslint-enable */}
            </Screen>
        </div>
    );
}

FloatingContent.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onClose: PropTypes.func
};

FloatingContent.defaultProps = {
    onClose: identity
};

export default FloatingContent;
