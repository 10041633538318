function uniqByKey(key = "", items = []) {
    if (!key) {
        throw new Error("key should be provided!");
    }

    if (!Array.isArray(items)) {
        throw new Error("provided list is not array!");
    }

    return [...new Map(items.map(item => [item[key], item])).values()];
}

export default uniqByKey;
