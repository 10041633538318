import React from "react";
import PropTypes from "prop-types";
import Box from "ui-box";
import cx from "classnames";
import { identity } from "ramda";
import css from "./title.css";
import Text from "../Text";
import FlexBox from "../FlexBox";
import More from "../More";
import { RightArrowMedium } from "../../svg";

function Title({ tag, children, arrow, more, moreArrow, render, onClick, onMoreClick }) {
    return (
        <FlexBox className={cx(css.title)} row middle spaceBetween>
            {render ? (
                render()
            ) : (
                <Text
                    tag={tag}
                    size={"big"}
                    weight={"bold"}
                    color={"gray"}
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={onClick}
                >
                    {children}
                    {arrow && (
                        <Box display={"inline-flex"} marginTop={"-1px"}>
                            <RightArrowMedium />
                        </Box>
                    )}
                </Text>
            )}

            {moreArrow && (
                <More onClick={onMoreClick} arrow>
                    {moreArrow}
                </More>
            )}
            {more && <More onClick={onMoreClick}>{more}</More>}
        </FlexBox>
    );
}

Title.propTypes = {
    tag: PropTypes.string,
    children: PropTypes.node,
    arrow: PropTypes.bool,
    more: PropTypes.node,
    moreArrow: PropTypes.node,
    render: PropTypes.func,
    onClick: PropTypes.func,
    onMoreClick: PropTypes.func
};

Title.defaultProps = {
    tag: "h3",
    children: "",
    arrow: false,
    more: "",
    moreArrow: "",
    render: null,
    onClick: identity,
    onMoreClick: identity
};

export default Title;
