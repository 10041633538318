import React, { createElement, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { identity } from "ramda";
import css from "./checkbox.css";
import FlexBox from "../FlexBox";
import Text from "../Text";
import { Checked, CheckedR, UnChecked } from "../../svg";

function CheckBox({ label, checked, onChange, readOnly }) {
    const [isChecked, setIsChecked] = useState(checked);

    const handleClick = () => {
        if (!readOnly) {
            setIsChecked(!isChecked);
        }
    };

    useEffect(() => {
        onChange(isChecked);
    }, [isChecked]);

    return (
        <FlexBox className={cx(css.checkbox)} row spaceBetween>
            {Array.isArray(label) ? (
                <FlexBox col>
                    <Text list={label} />
                </FlexBox>
            ) : (
                <Text>{label}</Text>
            )}
            {isChecked ? (
                createElement(readOnly ? CheckedR : Checked, {
                    onClick: handleClick
                })
            ) : (
                <UnChecked onClick={handleClick} />
            )}
        </FlexBox>
    );
}

CheckBox.propTypes = {
    label: PropTypes.node.isRequired,
    checked: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func
};

CheckBox.defaultProps = {
    checked: false,
    readOnly: false,
    onChange: identity
};

export default CheckBox;
