import {
    REQUEST_GET_SENT_EST_METADATA,
    FAILURE_GET_SENT_EST_METADATA,
    SUCCESS_GET_SENT_EST_METADATA,
    REQUEST_GET_EST_BY_SHOP,
    FAILURE_GET_EST_BY_SHOP,
    SUCCESS_GET_EST_BY_SHOP,
    REQUEST_GET_SHOP_EST,
    FAILURE_GET_SHOP_EST,
    SUCCESS_GET_SHOP_EST
} from "../actionTypes";

const initialState = {
    reqid: "",
    accReqNo: "",
    insuName: "",
    sentEst: {},
    est: {},
    estList: [],
    errors: {
        getSentEstMetadata: null,
        getEstByShop: null,
        getShopEst: null
    }
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_GET_SENT_EST_METADATA: {
            return {
                ...state,
                reqid: "",
                accReqNo: "",
                insuName: "",
                sentEst: {},
                errors: {
                    ...state.errors,
                    getSentEstMetadata: null
                }
            };
        }

        case FAILURE_GET_SENT_EST_METADATA: {
            return {
                ...state,
                reqid: "",
                accReqNo: "",
                insuName: "",
                sentEst: {},
                errors: {
                    ...state.errors,
                    getSentEstMetadata: payload
                }
            };
        }

        case SUCCESS_GET_SENT_EST_METADATA: {
            return {
                ...state,
                reqid: payload.reqid,
                accReqNo: payload.accReqNo,
                insuName: payload.insuName,
                sentEst: {
                    ...payload
                },
                errors: {
                    ...state.errors,
                    getSentEstMetadata: null
                }
            };
        }

        case REQUEST_GET_EST_BY_SHOP: {
            return {
                ...state,
                estList: [],
                errors: {
                    ...state.errors,
                    getEstByShop: null
                }
            };
        }

        case FAILURE_GET_EST_BY_SHOP: {
            return {
                ...state,
                estList: [],
                errors: {
                    ...state.errors,
                    getEstByShop: payload
                }
            };
        }

        case SUCCESS_GET_EST_BY_SHOP: {
            return {
                ...state,
                estList: payload,
                errors: {
                    ...state.errors,
                    getEstByShop: null
                }
            };
        }

        case REQUEST_GET_SHOP_EST: {
            return {
                ...state,
                est: {},
                errors: {
                    ...state.errors,
                    getShopEst: null
                }
            };
        }

        case FAILURE_GET_SHOP_EST: {
            return {
                ...state,
                est: {},
                errors: {
                    ...state.errors,
                    getShopEst: null
                }
            };
        }

        case SUCCESS_GET_SHOP_EST: {
            return {
                ...state,
                est: payload,
                errors: {
                    ...state.errors,
                    getShopEst: null
                }
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
