import { createStore } from "redux";
import thunk from "redux-thunk";
import * as api from "~/services/api";
import * as apiNext from "~/services/api/index.next";
import createComposeMiddleware from "./createComposeMiddleware";
import reducers from "./reducers";

function configureStore() {
    const composeMiddleware = createComposeMiddleware(process.env.MODE);
    const middlewares = [thunk.withExtraArgument({ ...api, ...apiNext })];

    return createStore(reducers, composeMiddleware(...middlewares));
}

export default configureStore;
