function stringToFloat(text = "", toFixed = -1) {
    if (!text) {
        return 0;
    }

    let parsedFloat = parseFloat(text);

    if (toFixed > -1) {
        parsedFloat = parsedFloat.toFixed(toFixed);
    }

    return Number(parsedFloat);
}

export default stringToFloat;
