import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { identity } from "ramda";
import css from "./nav-bar.css";
import FlexBox from "../FlexBox";
import Text from "../Text";
import { BackBtn, IcoClose } from "../../svg";

const TOOLBAR_LEFT_MAP = {
    back: <BackBtn />
};

function NavBar({ toolbar, onClickLeft, dark }) {
    const { title, left, right } = toolbar;

    return (
        <FlexBox
            tag={"header"}
            className={cx(css.navBar, {
                [css.navBarDark]: dark
            })}
            row
        >
            <FlexBox onClick={onClickLeft} className={cx(css.navBarLeft)} center middle>
                {/* TODO optimize later */}
                {left === "close" ? <IcoClose dark={dark} /> : TOOLBAR_LEFT_MAP[left] || ""}
            </FlexBox>
            <FlexBox className={cx(css.navBarTitle)} center middle>
                <Text color={dark ? "white" : "grey"} size={"big"} weight={"bold"}>
                    {title}
                </Text>
            </FlexBox>
            <FlexBox className={cx(css.navBarRight)} center middle>
                {right && right()}
            </FlexBox>
        </FlexBox>
    );
}

NavBar.propTypes = {
    toolbar: PropTypes.shape({
        title: PropTypes.node,
        left: PropTypes.string,
        right: PropTypes.func
    }).isRequired,
    dark: PropTypes.bool,
    onClickLeft: PropTypes.func
};

NavBar.defaultProps = {
    dark: false,
    onClickLeft: identity
};

export default NavBar;
