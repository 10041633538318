import { combineReducers } from "redux";
import assets from "./assets";
import bootstrap from "./bootstrap";
import form from "./form";
import received from "./received";
import shop from "./shop";
import reviews from "./reviews";
import portfolio from "./portfolio";
import booking from "./booking";

export default combineReducers({
    bootstrap,
    form,
    assets,
    received,
    shop,
    reviews,
    portfolio,
    booking
});
