import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

function Portal({ rootEl, el, children }) {
    const portalEl = useRef(el);

    useEffect(() => {
        rootEl.appendChild(portalEl.current);

        // TODO for now
        document.querySelector("#app-root").classList.add("is-hidden");

        return () => {
            // TODO for now
            document.querySelector("#app-root").classList.remove("is-hidden");

            rootEl.removeChild(portalEl.current);
        };
    }, [rootEl]);

    return createPortal(children, portalEl.current);
}

Portal.propTypes = {
    el: PropTypes.instanceOf(Element).isRequired,
    children: PropTypes.node.isRequired,
    rootEl: PropTypes.PropTypes.instanceOf(Element)
};

Portal.defaultProps = {
    rootEl: document.getElementById("modal-root")
};

export default Portal;
