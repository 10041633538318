import { connect } from "react-redux";
import qs from "query-string";
import { ReqEstStep1 } from "~/pages";
import {
    parseQueries,
    saveUserkey,
    getJumbotronData,
    getCarInsMetadata,
    saveEstFieldsStep1,
    checkValidPromoCode
} from "~/store/actions";
import { VIEW_RECEIVED, VIEW_SHOP_EST } from "../../routes";

function mapStateToProps({ bootstrap: { promocode, jumbotron }, form }) {
    return {
        jumbotron,
        promocode,
        ...form
    };
}

function mapDispatchToProps(dispatch) {
    return {
        initialize: pushTo => {
            const query = window.location.search;
            const parsedQuery = qs.parse(query);

            dispatch(saveUserkey());
            dispatch(getJumbotronData());
            dispatch(parseQueries(query));
            dispatch(getCarInsMetadata());

            // TODO move to utils or somewhere
            const queryMap = {
                reqid: VIEW_RECEIVED,
                estid: VIEW_SHOP_EST
            };
            const parsedQueryKeys = Object.keys(parsedQuery);
            const route = parsedQueryKeys.find(key => !!queryMap[key]);

            if (route && typeof pushTo === "function") {
                pushTo(`${queryMap[route]}${query}`);
            }
        },

        saveEstFieldsStep1: ({ name, value }) => {
            dispatch(saveEstFieldsStep1({ name, value }));
        },

        checkValidPromoCode: cb => {
            dispatch(checkValidPromoCode(cb));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReqEstStep1);
