import { curry } from "ramda";

function padZeros(size, num) {
    let s = `${num}`;

    while (s.length < size) {
        s = `0${s}`;
    }

    return s;
}

export default curry(padZeros);
