import axios from "axios";
import { compose } from "ramda";
import errorHandler from "./errorHandler";

// NOTE
// | in development, use proxy
// | please, do not add host
function createInstance({ MODE = "development", API_ENDPOINT, API_VERSION }) {
    const baseURL = MODE === "development" ? `/${API_VERSION}` : `${API_ENDPOINT}/${API_VERSION}`;

    return (options = {}) =>
        axios.create({
            timeout: 30000,
            baseURL,
            ...options
        });
}

const getInstance = compose(errorHandler, createInstance(process.env));
const instance = getInstance();
instance.withOptions = getInstance;

function createExternal({ MODE = "development", API_ENDPOINT }) {
    return (options = {}) =>
        ({ method = "get", url, ...restArgs } = {}) => {
            const externalInstance = instance.withOptions({
                ...options,
                baseURL: MODE === "development" ? `/${options.baseURL}` : `${API_ENDPOINT}/${options.baseURL}`
            });

            return externalInstance({
                method,
                url,
                ...restArgs
            });
        };
}

export const external = createExternal(process.env);
export default instance;
