import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import css from "./tab.css";
import FlexBox from "../FlexBox";
import Text from "../Text";

function Tab({ name, size, selected }) {
    return (
        <FlexBox className={cx(css.tab)} center>
            <Text weight={selected ? "bold" : "normal"} size={size} color={selected ? "gray" : "gray700"}>
                {name}
            </Text>
        </FlexBox>
    );
}

Tab.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.string,
    selected: PropTypes.bool
};

Tab.defaultProps = {
    size: "big",
    selected: false
};

export default Tab;
