import React from "react";
import PropTypes from "prop-types";
import { identity } from "ramda";

function Wrap({ children, onClick, ...attrs }) {
    return (
        <div
            style={{
                ...attrs
            }}
            onClick={onClick}
            role={"presentation"}
        >
            {children}
        </div>
    );
}

Wrap.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func
};

Wrap.defaultProps = {
    children: "",
    onClick: identity
};

export default Wrap;
