import { identity } from "ramda";
import * as types from "../actionTypes";
import { REQ_EST_DONE } from "../../routes";

const PLATE_NO_MAX_LENGTH = 9;
const YEAR_MAX_LENGTH = 4;

export function saveEstFieldsStep1({ name, value }) {
    let v = value;
    if (name === "plateNo") {
        const valueWithoutWhitespaces = value.replace(/\s/g, "");
        v =
            valueWithoutWhitespaces.length > PLATE_NO_MAX_LENGTH
                ? valueWithoutWhitespaces.slice(0, PLATE_NO_MAX_LENGTH)
                : valueWithoutWhitespaces;
    } else if (name === "year") {
        v = value.length > YEAR_MAX_LENGTH ? value.slice(0, YEAR_MAX_LENGTH) : value;
    }

    return {
        type: types.SAVE_EST_FIELDS_STEP1,
        payload: {
            name,
            value: v
        }
    };
}

export function saveEstFieldsStep2(fields) {
    return {
        type: types.SAVE_EST_FIELDS_STEP2,
        payload: {
            ...fields
        }
    };
}

export function saveBrandName(brandName) {
    return {
        type: types.SAVE_BRAND_NAME,
        payload: brandName
    };
}

export function saveAddress({ name, value }) {
    return {
        type: types.SAVE_ADDRESS,
        payload: {
            name,
            value
        }
    };
}

export function getImageIds(files) {
    return async (dispatch, getState, api) => {
        const {
            bootstrap: { userkey }
        } = getState();

        dispatch({
            type: types.REQUEST_GET_IMAGE_IDS
        });

        try {
            const data = await Promise.all(files.map(file => api.uploadImages({ files: file, userkey })));

            const imageIds = data.map(({ fileid }) => fileid);

            dispatch({
                type: types.SUCCESS_GET_IMAGE_IDS,
                payload: imageIds
            });
        } catch (err) {
            dispatch({
                type: types.FAILURE_GET_IMAGE_IDS,
                payload: err
            });
        }
    };
}

export function getLocationIds() {
    return async (dispatch, getState, api) => {
        const {
            bootstrap: { userkey },
            form: { addressA, addressB }
        } = getState();

        dispatch({
            type: types.REQUEST_GET_LOCATION_IDS
        });

        try {
            const data = await Promise.all(
                [addressA, addressB]
                    .filter(address => Object.keys(address).length)
                    .map(({ location: { lat, lon } }) => api.submitUserLocation({ lat, lon, userkey }))
            );

            const locationIds = data.map(({ result: { user_location_id: userLocationId } }) => userLocationId);

            dispatch({
                type: types.SUCCESS_GET_LOCATION_IDS,
                payload: locationIds
            });
        } catch (err) {
            dispatch({
                type: types.FAILURE_GET_LOCATION_IDS,
                payload: err
            });
        }
    };
}

export function submitEstForm() {
    return async (dispatch, getState, api) => {
        const {
            bootstrap: { userkey, vinEnc },
            form
        } = getState();

        dispatch({
            type: types.REQUEST_SUBMIT_EST_FORM
        });

        try {
            const { reqid } = await api.submitEstForm({
                userkey,
                vinEnc,
                form
            });

            dispatch({
                type: types.SUCCESS_SUBMIT_EST_FORM,
                payload: reqid
            });

            location.replace(REQ_EST_DONE);
        } catch (err) {
            dispatch({
                type: types.FAILURE_SUBMIT_EST_FORM
            });

            if (err.response) {
                const {
                    data: { error }
                } = err.response;

                alert(error?.error_msg ?? "견적서 발송에 실패했습니다.");
            }
        }
    };
}

export function getCarInsMetadata() {
    return async (dispatch, getState, api) => {
        const { bootstrap } = getState();

        dispatch({
            type: types.REQUEST_GET_CAR_INSURANCE_INFO
        });

        try {
            if (!bootstrap.promocode) {
                throw new Error("invalid access (no promocode)");
            }

            const {
                acc_req_no: accReqNo,
                insurance_corp: { insuName },
                requestList
            } = await api.getCarInsMetadata(bootstrap);

            dispatch({
                type: types.SUCCESS_GET_CAR_INSURANCE_INFO,
                payload: {
                    insuName,
                    accReqNo,
                    requestList
                }
            });
        } catch (err) {
            dispatch({
                type: types.FAILURE_GET_CAR_INSURANCE_INFO,
                payload: err
            });
        }
    };
}

export function checkValidPromoCode(cb = identity) {
    return async (dispatch, getState, api) => {
        const { form } = getState();

        dispatch({
            type: types.REQUEST_CHECK_VALID_PROMO_CODE
        });

        try {
            const { result } = await api.checkValidPromoCode({
                promocode: form.promotioncode
            });

            if (typeof cb === "function") {
                cb();
            }

            // NOTE do nothing at the moment
            dispatch({
                type: types.SUCCESS_CHECK_VALID_PROMO_CODE,
                payload: result
            });
        } catch (err) {
            dispatch({
                type: types.FAILURE_CHECK_VALID_PROMO_CODE,
                payload: err
            });

            if (err.response) {
                const {
                    data: { error }
                } = err.response;

                // TODO use modal later
                // eslint-disable-next-line no-alert
                window.alert(error.error_msg);
            }
        }
    };
}
