import * as types from "../actionTypes";

export function initBookingInfoFields(fields) {
    return {
        type: types.INIT_BOOKING_INFO_FIELDS,
        payload: fields
    };
}

export function updateBookingInfoField({ name, value }) {
    return {
        type: types.UPDATE_BOOKING_INFO_FIELD,
        payload: {
            name,
            value
        }
    };
}

export function cancelBooking() {
    return async (dispatch, getState, api) => {
        const {
            bootstrap: { estid },
            booking: {
                form: { bookedDate, ...form }
            }
        } = getState();

        dispatch({
            type: types.REQUEST_CANCEL_BOOKING
        });

        try {
            await api.cancelBooking({
                data: {
                    updatetype: "cancelbookbyuser",
                    ...form
                },
                estid
            });

            dispatch({
                type: types.SUCCESS_CANCEL_BOOKING
            });
        } catch (err) {
            dispatch({
                type: types.FAILURE_CANCEL_BOOKING,
                payload: err
            });
        }
    };
}

export function requestBooking() {
    return async (dispatch, getState, api) => {
        const {
            bootstrap: { estid },
            booking: {
                form: { bookedDate, ...form }
            }
        } = getState();

        dispatch({
            type: types.REQUEST_REQUEST_BOOKING
        });

        try {
            await api.requestBooking({
                data: {
                    updatetype: "reqbooking",
                    ...form
                },
                estid
            });

            dispatch({
                type: types.SUCCESS_REQUEST_BOOKING
            });
        } catch (err) {
            dispatch({
                type: types.FAILURE_REQUEST_BOOKING,
                payload: err
            });
        }
    };
}

export function updateBooking() {
    return async (dispatch, getState, api) => {
        const {
            bootstrap: { estid },
            booking: {
                form: { bookedDate, ...form }
            }
        } = getState();

        dispatch({
            type: types.REQUEST_UPDATE_BOOKING
        });

        try {
            await api.updateBooking({
                data: {
                    updatetype: "modifybookbyuser",
                    ...form
                },
                estid
            });

            dispatch({
                type: types.SUCCESS_UPDATE_BOOKING
            });
        } catch (err) {
            dispatch({
                type: types.FAILURE_UPDATE_BOOKING,
                payload: err
            });
        }
    };
}
