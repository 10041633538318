import { isEmpty, isNil } from "ramda";

function extractInner(htmlString, wrapper = ["<body>", "</body>"]) {
    if (isEmpty(htmlString) || isNil(htmlString)) {
        return htmlString;
    }

    const [, contents] = new RegExp([wrapper[0], "([\\s\\S]*?)", wrapper[1]].join("")).exec(htmlString);

    return contents;
}

export default extractInner;
