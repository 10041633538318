import { uniqByKey } from "~/utils";
import { REQUEST_GET_REVIEWS, SUCCESS_GET_REVIEWS, FAILURE_GET_REVIEWS } from "../actionTypes";

export function getReviews() {
    return async (dispatch, getState, api) => {
        const {
            bootstrap: { shopid },
            shop: { reviewcnt },
            reviews: { items: prevReviews, currentShopId, isLoading, nextPageCnt }
        } = getState();

        const isShopDiff = shopid !== currentShopId;
        const pageCnt = isShopDiff ? 1 : nextPageCnt + 1;
        const shouldProceed = (isShopDiff ? [] : prevReviews).length < parseInt(reviewcnt, 10);

        if (shouldProceed && !isLoading) {
            dispatch({
                type: REQUEST_GET_REVIEWS,
                payload: {
                    shopid
                }
            });

            try {
                const reviews = await api.getReviews({
                    pageCnt,
                    shopid
                });
                const hasMore = reviews.length > 0;

                dispatch({
                    type: SUCCESS_GET_REVIEWS,
                    payload: {
                        currentShopId: shopid,
                        nextPageCnt: pageCnt,
                        reviews: hasMore
                            ? uniqByKey("reviewid", [...(isShopDiff ? [] : prevReviews), ...reviews])
                            : prevReviews
                    }
                });
            } catch (err) {
                dispatch({
                    type: FAILURE_GET_REVIEWS,
                    payload: err
                });
            }
        }
    };
}
