import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { identity } from "ramda";
import { withErrorBoundary } from "@sentry/react";
import { MREQ_AMPLITUDE } from "__presets";
import { Emoji, FlexBox, Grid, Screen, Text, Wrap } from "~/packages/ui/mobile";
import useAmplitude from "../../amplitude";

function BrandList({ setNavToolbar, topList, byLetter, getCarBrandList, handleSelectBrandName }) {
    const history = useHistory();
    // TODO change this condition later
    const isLoading = topList.length === 0 && Object.keys(byLetter).length === 0;
    const amplitude = useAmplitude();

    useEffect(() => {
        const init = async () => {
            await getCarBrandList();
        };

        init();

        setNavToolbar({
            title: "브랜드 선택",
            left: "back"
        });
    }, [getCarBrandList, setNavToolbar]);

    if (isLoading) {
        return "";
    }

    return (
        <Screen hasNavi stretch>
            <Wrap padding={"24px 20px 0"}>
                <Text
                    size={"biggest"}
                    weight={"bold"}
                    list={[
                        "차량 브랜드를",
                        <>
                            선택해주세요. <Emoji label={"hand"} symbol={"💁‍♂️"} />
                        </>
                    ]}
                />
            </Wrap>

            <Wrap padding={"48px 20px 24px"}>
                <Text size={"small"}>인기 브랜드</Text>
            </Wrap>

            <Wrap padding={"0 20px"}>
                <Grid cols={"repeat(auto-fill, 64px)"} gap={"24px"}>
                    {topList.map(({ brandname, brandnameko, imgColorUrl }) => {
                        return (
                            <Wrap
                                key={brandname}
                                width={"64px"}
                                onClick={() => {
                                    amplitude.logEvent(MREQ_AMPLITUDE.selectMreqCarbrand);
                                    handleSelectBrandName(brandnameko);

                                    history.goBack();
                                }}
                            >
                                <Wrap
                                    width={"64px"}
                                    height={"64px"}
                                    boxShadow={"0 2px 8px 0 #eaeaea"}
                                    borderRadius={"24px"}
                                    overflow={"hidden"}
                                    backgroundColor={"#fff"}
                                >
                                    <FlexBox middle center>
                                        <img
                                            src={imgColorUrl}
                                            alt={brandnameko}
                                            style={{
                                                width: "64px",
                                                height: "64px"
                                            }}
                                        />
                                    </FlexBox>
                                </Wrap>

                                <Wrap marginTop={"8px"}>
                                    <Text align={"center"}>{brandnameko}</Text>
                                </Wrap>
                            </Wrap>
                        );
                    })}
                </Grid>
            </Wrap>

            {Object.keys(byLetter).map(key => {
                return (
                    <Wrap key={key} padding={"48px 20px"}>
                        <Text
                            style={{
                                marginBottom: "24px"
                            }}
                        >
                            차량 브랜드명 - {key}
                        </Text>
                        <Grid cols={"repeat(auto-fill, 64px)"} gap={"24px"}>
                            {byLetter[key].map(({ brandname, brandnameko, imgColorUrl }) => {
                                return (
                                    <Wrap
                                        key={brandname}
                                        width={"64px"}
                                        onClick={() => {
                                            amplitude.logEvent(MREQ_AMPLITUDE.selectMreqCarbrand);
                                            handleSelectBrandName(brandnameko);

                                            history.goBack();
                                        }}
                                    >
                                        <Wrap
                                            width={"64px"}
                                            height={"64px"}
                                            boxShadow={"0 2px 8px 0 #eaeaea"}
                                            borderRadius={"24px"}
                                            overflow={"hidden"}
                                            backgroundColor={"#fff"}
                                        >
                                            <FlexBox middle center>
                                                <img
                                                    src={imgColorUrl}
                                                    alt={brandnameko}
                                                    style={{
                                                        width: "64px",
                                                        height: "64px"
                                                    }}
                                                />
                                            </FlexBox>
                                        </Wrap>

                                        <Wrap marginTop={"8px"}>
                                            <Text align={"center"}>{brandnameko}</Text>
                                        </Wrap>
                                    </Wrap>
                                );
                            })}
                        </Grid>
                    </Wrap>
                );
            })}
        </Screen>
    );
}

BrandList.propTypes = {
    setNavToolbar: PropTypes.func.isRequired,
    topList: PropTypes.arrayOf(
        PropTypes.shape({
            brandnameko: PropTypes.string.isRequired,
            imgColorUrl: PropTypes.string.isRequired
        }).isRequired
    ).isRequired,
    byLetter: PropTypes.objectOf(
        // eslint-disable-next-line consistent-return
        (propValue, key, componentName, location, propFullName) => {
            const val = propValue[key];
            const { brandname, brandnameko, imgColorUrl } = val[0];
            const hasKeys = brandname && brandnameko && imgColorUrl;

            if (!Array.isArray(val) || !hasKeys) {
                return new Error(
                    `Invalid prop \`${propFullName}\` supplied to \`${componentName}\`. Validation failed.`
                );
            }
        }
    ).isRequired,
    getCarBrandList: PropTypes.func,
    handleSelectBrandName: PropTypes.func
};

BrandList.defaultProps = {
    getCarBrandList: identity,
    handleSelectBrandName: identity
};

export default withErrorBoundary(BrandList, {
    fallback: "an error has occurred"
});
