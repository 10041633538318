import React, { useEffect, useState, createElement } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { identity } from "ramda";
import { withErrorBoundary } from "@sentry/react";
import { RightArrow } from "~/packages/ui/svg";
import {
    Screen,
    Wrap,
    Text,
    Tabs,
    Tab,
    TabPanel,
    Divider,
    Card,
    Button,
    Title,
    TextEllipsis
} from "~/packages/ui/mobile";
import { RepairPrice, SatisfactionGraph } from "~/components";
import { phoneCall } from "~/services/device";
import { stringToFloat } from "~/utils";
import { VIEW_SHOP } from "../../routes";

function ViewShopEst({ setNavToolbar, est, shop, price, parseQueries, getShopEst }) {
    const history = useHistory();
    const [currentTab, setCurrentTab] = useState(0);
    const [isSingle, setIsSingle] = useState(false);
    const [insurance, setInsurance] = useState({});
    const [cash, setCash] = useState({});

    useEffect(() => {
        const init = async () => {
            parseQueries();
            await getShopEst();
        };

        init();

        setNavToolbar({
            title: "상세 견적",
            left: "back"
        });
    }, []);

    useEffect(() => {
        setIsSingle(price.length === 1);

        // TODO optimized
        price.forEach(typedObj => {
            const priceMap = {
                repair: 0,
                part: 0,
                option: 0
            };

            const list = typedObj.item.reduce(
                (acc, typedItem) => {
                    if (typedItem.type === "part") {
                        priceMap[typedItem.type] += typedItem.price * parseInt(typedItem.qty, 10);
                    } else {
                        priceMap[typedItem.type] += typedItem.price;
                    }

                    return {
                        ...acc,
                        [typedItem.type]: [...acc[typedItem.type], typedItem]
                    };
                },
                {
                    repair: [],
                    part: [],
                    option: []
                }
            );

            if (typedObj.type === "insurance") {
                setInsurance({
                    ...typedObj,
                    ...list,
                    priceMap
                });
            } else {
                setCash({
                    ...typedObj,
                    ...list,
                    priceMap
                });
            }
        });
    }, [price]);

    const handleClick = (idx, evt) => {
        evt.preventDefault();

        setCurrentTab(idx);
    };

    return (
        <Screen hasNavi stretch>
            {!!Object.keys(shop).length && (
                <>
                    <Wrap padding={"24px 20px"} backgroundColor={"#fff"}>
                        <Card>
                            <Wrap marginBottom={"12px"}>
                                <Text tag={"h2"} size={"big"} weight={"bold"}>
                                    {shop.shopname}
                                </Text>

                                <Wrap marginTop={"4px"}>
                                    <Text size={"small"} color={"gray700"}>
                                        {`${shop.area.depth1} ${shop.area.depth2} ${shop.address}`}
                                    </Text>
                                </Wrap>
                            </Wrap>

                            <SatisfactionGraph
                                ratio={stringToFloat(shop.avg_satisfaction, 1)}
                                data={[
                                    {
                                        title: "작업전문성",
                                        progress: {
                                            max: 5,
                                            value: stringToFloat(shop.avg_quality, 1)
                                        }
                                    },
                                    {
                                        title: "친절도",
                                        progress: {
                                            max: 5,
                                            value: stringToFloat(shop.avg_kindness, 1)
                                        }
                                    },
                                    {
                                        title: "견적 정확도",
                                        progress: {
                                            max: 5,
                                            value: stringToFloat(shop.avg_est_accuracy, 1)
                                        }
                                    }
                                ]}
                            />

                            <Wrap marginTop={"17px"}>
                                <Text
                                    size={"small"}
                                    align={"center"}
                                    onClick={() => {
                                        history.push(`${VIEW_SHOP}?shopid=${shop.shopid}`);
                                    }}
                                >
                                    업체 상세정보 확인하기 <RightArrow />
                                </Text>
                            </Wrap>
                        </Card>
                    </Wrap>

                    {isSingle ? (
                        <>
                            <Wrap padding={"0 20px"} backgroundColor={"#fff"}>
                                <Text size={"big"} weight={"bold"}>
                                    수리상세내역 ({insurance.type !== "insurance" && "비"}
                                    보험수리)
                                </Text>
                            </Wrap>

                            <Divider />

                            {createElement(RepairPrice, {
                                type: insurance.type,
                                price: insurance.type === "insurance" ? insurance : cash,
                                period: est.period,
                                periodUnit: est.period_unit
                            })}
                        </>
                    ) : (
                        <>
                            <Tabs currentTab={currentTab} onClick={handleClick}>
                                <Tab name={"보험수리"} />
                                <Tab name={"비보험수리"} />
                            </Tabs>

                            <TabPanel currentTab={currentTab} idx={0}>
                                <RepairPrice
                                    type={insurance.type}
                                    price={insurance}
                                    period={est.period}
                                    periodUnit={est.period_unit}
                                />
                            </TabPanel>

                            <TabPanel currentTab={currentTab} idx={1}>
                                <RepairPrice
                                    type={cash.type}
                                    price={cash}
                                    period={est.period}
                                    periodUnit={est.period_unit}
                                />
                            </TabPanel>
                        </>
                    )}

                    <Wrap padding={"0 20px"}>
                        <Divider color={"black"} />
                    </Wrap>

                    <Wrap padding={"0 20px"} backgroundColor={"#fff"}>
                        <Wrap padding={"32px 0 56px"}>
                            <Title
                                render={() => (
                                    <Text tag={"h3"} size={"big"} weight={"bold"}>
                                        견적 내용 설명
                                    </Text>
                                )}
                            />

                            {shop.shopdesc && <TextEllipsis text={shop.shopdesc} />}
                        </Wrap>

                        <Wrap marginBottom={"42px"}>
                            <Button
                                style={{
                                    border: "none",
                                    backgroundColor: "#000",
                                    color: "#fff"
                                }}
                                onClick={() => phoneCall(shop.callmentnumber, shop.telnum)}
                            >
                                전화하기
                            </Button>
                        </Wrap>
                    </Wrap>
                </>
            )}
        </Screen>
    );
}

ViewShopEst.propTypes = {
    setNavToolbar: PropTypes.func.isRequired,
    shop: PropTypes.shape({
        shopid: PropTypes.string,
        shopname: PropTypes.string,
        avg_satisfaction: PropTypes.string,
        avg_quality: PropTypes.string,
        avg_kindness: PropTypes.string,
        avg_est_accuracy: PropTypes.string,
        shopdesc: PropTypes.string,
        address: PropTypes.string,
        callmentnumber: PropTypes.string,
        telnum: PropTypes.string,
        area: PropTypes.shape({
            depth1: PropTypes.string,
            depth2: PropTypes.string
        })
    }),
    est: PropTypes.shape({
        period: PropTypes.number,
        period_unit: PropTypes.string
    }),
    price: PropTypes.arrayOf(PropTypes.shape({})),
    parseQueries: PropTypes.func,
    getShopEst: PropTypes.func
};

ViewShopEst.defaultProps = {
    est: {},
    shop: {},
    price: [],
    parseQueries: identity,
    getShopEst: identity
};

export default withErrorBoundary(ViewShopEst, {
    fallback: "an error has occurred"
});
