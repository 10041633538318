import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withErrorBoundary } from "@sentry/react";
import { Button, Card, Divider, Emoji, Screen, Text, Title, Wrap } from "~/packages/ui/mobile";
import { CardocLogo } from "~/packages/ui/svg";
import { phoneCall } from "~/services/device";

function ReqEstDone({ setNavToolbar, reqid, accReqNo, insuName, tel }) {
    useEffect(() => {
        setNavToolbar({
            title: "완료",
            left: null
        });

        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [setNavToolbar]);

    return (
        <Screen hasNavi>
            <Text
                size={"biggest"}
                weight={"bold"}
                list={[
                    "견적요청이",
                    <>
                        완료되었습니다! <Emoji symbol={"🎉"} label={"hooray"} />
                    </>
                ]}
            />

            <Wrap margin={"24px 0"}>
                <Text
                    color={"gray700"}
                    list={[
                        "요청하신 견적이 설정 지역 업체들에게",
                        "전달되었습니다. 견적 도착 알림은 카카오톡으로",
                        "보내드립니다."
                    ]}
                />
            </Wrap>

            <Wrap marginBottom={"16px"}>
                <Wrap marginBottom={"8px"}>
                    <Card>
                        <Text weight={"bold"}>
                            카닥 견적 번호 : <Text type={"inline"}>{reqid}</Text>
                        </Text>
                    </Card>
                </Wrap>
                {accReqNo && (
                    <Card>
                        <Text weight={"bold"}>
                            {insuName} 사고 번호 : <Text type={"inline"}>{accReqNo}</Text>
                        </Text>
                    </Card>
                )}
            </Wrap>

            <Divider type={"dashed"} />

            <Wrap margin={"16px 0 76px"}>
                <Card>
                    <Title>카닥과 상담이 필요하신가요?</Title>

                    <Text
                        size={"small"}
                        color={"gray700"}
                        list={[
                            "고객센터 운영시간 - 평일 09:00 ~ 18:00",
                            "(점심시간 12:30 ~ 13:30, 주말 및 공휴일 휴무)"
                        ]}
                    />

                    <Divider forText />

                    <Button color={"outlined"} onClick={() => phoneCall(tel)}>
                        전화 상담하기
                    </Button>
                </Card>
            </Wrap>

            <Wrap marginBottom={"61px"} textAlign={"center"}>
                <CardocLogo />
            </Wrap>
        </Screen>
    );
}

ReqEstDone.propTypes = {
    setNavToolbar: PropTypes.func.isRequired,
    reqid: PropTypes.string.isRequired,
    accReqNo: PropTypes.string.isRequired,
    insuName: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired
};

export default withErrorBoundary(ReqEstDone, {
    fallback: "an error has occurred"
});
