function generateFormData(data, formData = new FormData()) {
    Object.keys(data).forEach(key => {
        const val = [data[key]].flat();

        formData.append(key, ...val);
    });

    return formData;
}

export default generateFormData;
