// TODO
// | catch all errors from apis
// | discuss with backend
function errorHandler(instance) {
    instance.interceptors.response.use(
        ({ data }) => data,
        error => Promise.reject(error)
    );

    return instance;
}

export default errorHandler;
