function splitContent(content = [], fn, symbol = "\n") {
    return content.split(symbol).map((v, idx) => {
        if (typeof fn === "function") {
            return fn(v, idx);
        }

        return v;
    });
}

export default splitContent;
