import { connect } from "react-redux";
import { ViewShop } from "~/pages";
import { parseQueries, getShop, getReviews, getPortfolio } from "~/store/actions";

function mapStateToProps({ shop, reviews, portfolio }) {
    return {
        shop,
        reviews: reviews.items,
        portfolio: portfolio.items
    };
}

function mapDispatchToProps(dispatch) {
    return {
        parseQueries: (query = window.location.search) => dispatch(parseQueries(query)),
        getShop: () => dispatch(getShop()),
        getReviews: () => dispatch(getReviews()),
        getPortfolio: () => dispatch(getPortfolio())
    };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,

        // TODO implement logic later
        getWorkingDays: (days = {}) => {
            return [days.weekdays, days.weekends, days.holidays];
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ViewShop);
