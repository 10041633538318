import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { identity } from "ramda";
import { withErrorBoundary } from "@sentry/react";
import { Screen, Title, Text, Wrap, Tabs, Tab, TabPanel } from "~/packages/ui/mobile";
import { Review, Portfolio, ShopInfo, SatisfactionGraph } from "~/components";
import { stringToFloat } from "~/utils";
import { useIntersect } from "~/utils/hooks";

function ViewShop({
    setNavToolbar,
    parseQueries,
    getShop,
    getReviews,
    getPortfolio,
    shop,
    reviews,
    portfolio,
    getWorkingDays
}) {
    const [currentTab, setCurrentTab] = useState(0);

    useEffect(() => {
        const init = async () => {
            parseQueries();
            await getShop();
            await getReviews();
            await getPortfolio();
        };

        init();

        setNavToolbar({
            title: "업체 상세정보",
            left: "back"
        });
    }, []);

    const handleClick = (idx, evt) => {
        evt.preventDefault();

        setCurrentTab(idx);
    };

    const [, setReviewsLastRef] = useIntersect(async (entry, observer) => {
        observer.unobserve(entry.target);
        await getReviews();
        observer.observe(entry.target);
    }, {});

    const [, setPortfolioLastRef] = useIntersect(async (entry, observer) => {
        observer.unobserve(entry.target);
        await getPortfolio();
        observer.observe(entry.target);
    }, {});

    return (
        <Screen hasNavi stretch>
            {shop.shopname && (
                <>
                    <Wrap padding={"24px 20px"} backgroundColor={"#fff"}>
                        <Title
                            render={() => (
                                <Text tag={"h2"} size={"24px"} weight={"bold"}>
                                    {shop.shopname}
                                </Text>
                            )}
                        />

                        <SatisfactionGraph
                            ratio={stringToFloat(shop.avg_satisfaction, 1)}
                            data={[
                                {
                                    title: "작업 전문성",
                                    progress: {
                                        max: 5,
                                        value: stringToFloat(shop.avg_quality, 1)
                                    }
                                },
                                {
                                    title: "친절도",
                                    progress: {
                                        max: 5,
                                        value: stringToFloat(shop.avg_kindness, 1)
                                    }
                                },
                                {
                                    title: "견적 정확도",
                                    progress: {
                                        max: 5,
                                        value: stringToFloat(shop.avg_est_accuracy, 1)
                                    }
                                }
                            ]}
                        />
                    </Wrap>

                    <Tabs currentTab={currentTab} onClick={handleClick}>
                        <Tab name={"이용후기"} />
                        <Tab name={"작업사례"} />
                        <Tab name={"업체정보"} />
                    </Tabs>

                    <TabPanel currentTab={currentTab} idx={0}>
                        {reviews.map(({ reviewid, score, userdesc, desc, moddttm }) => {
                            return (
                                <Review
                                    key={reviewid}
                                    score={score}
                                    userdesc={userdesc}
                                    desc={desc}
                                    moddttm={moddttm}
                                />
                            );
                        })}

                        <div ref={setReviewsLastRef} />
                    </TabPanel>

                    <TabPanel currentTab={currentTab} idx={1}>
                        <Portfolio data={portfolio} />

                        <div ref={setPortfolioLastRef} />
                    </TabPanel>

                    <TabPanel currentTab={currentTab} idx={2}>
                        <ShopInfo
                            shopDesc={shop.shopdesc}
                            shopAddress={`${shop.area.depth1} ${shop.area.depth2} ${shop.address}`}
                            shopNo={{
                                mobile: shop.callmentnumber,
                                tel: shop.telnum
                            }}
                            shopBusiness={{
                                weekdays: shop.business_hour_weekdays,
                                weekends: shop.business_hour_weekends,
                                holidays: shop.holidays
                            }}
                            getWorkingDays={getWorkingDays}
                        />
                    </TabPanel>
                </>
            )}
        </Screen>
    );
}

ViewShop.propTypes = {
    setNavToolbar: PropTypes.func.isRequired,
    parseQueries: PropTypes.func,
    shop: PropTypes.shape({
        shopid: PropTypes.string,
        shopname: PropTypes.string,
        avg_satisfaction: PropTypes.string,
        avg_quality: PropTypes.string,
        avg_kindness: PropTypes.string,
        avg_est_accuracy: PropTypes.string,
        shopdesc: PropTypes.string,
        address: PropTypes.string,
        callmentnumber: PropTypes.string,
        telnum: PropTypes.string,
        business_hour_weekdays: PropTypes.string,
        business_hour_weekends: PropTypes.string,
        holidays: PropTypes.string,
        area: PropTypes.shape({
            depth1: PropTypes.string,
            depth2: PropTypes.string
        })
    }),
    reviews: PropTypes.arrayOf(
        PropTypes.shape({
            reviewid: PropTypes.string,
            moddttm: PropTypes.string,
            score: PropTypes.string,
            desc: PropTypes.string,
            userdesc: PropTypes.string
        })
    ),
    portfolio: PropTypes.arrayOf(PropTypes.shape({})),
    getWorkingDays: PropTypes.func,
    getShop: PropTypes.func,
    getReviews: PropTypes.func,
    getPortfolio: PropTypes.func
};

ViewShop.defaultProps = {
    shop: {},
    reviews: [],
    portfolio: [],
    parseQueries: identity,
    getWorkingDays: identity,
    getShop: identity,
    getReviews: identity,
    getPortfolio: identity
};

export default withErrorBoundary(ViewShop, {
    fallback: "an error has occurred"
});
