export const APP = "/";
export const REQ_EST_STEP1 = APP;
export const REQ_EST_STEP2 = "/req-est-step2";
export const REQ_EST_DONE = "/req-est-done";
export const BRAND_LIST = "/brand-list";
export const VIEW_RECEIVED = "/view-received";
export const VIEW_SHOP = "/view-shop";
export const VIEW_SHOP_EST = "/view-shop-est";
export const VIEW_SENT_EST = "/view-sent-est";
export const BOOKING = "/booking";
