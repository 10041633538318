import React from "react";
import PropTypes from "prop-types";
import TimeAgo from "react-time-ago";
import { Section, Text, Wrap, FlexBox } from "~/packages/ui/mobile";
import { ReviewGood, ReviewBad } from "~/packages/ui/svg";
import { resolveDateString } from "~/utils";

function Review({ score, userdesc, desc, moddttm }) {
    return (
        <Section>
            <Section.Content>
                <Wrap padding={"24px 20px"}>
                    <FlexBox row middle>
                        <Wrap marginRight={"9px"}>
                            <figure>{score === "1" ? <ReviewGood /> : <ReviewBad />}</figure>
                        </Wrap>

                        <FlexBox col>
                            <Text>{userdesc}</Text>
                            <Text size={"smaller"} color={"gray700"}>
                                <TimeAgo date={resolveDateString(moddttm)} locale={"ko-KR"} timeStyle={"round"} />
                            </Text>
                        </FlexBox>
                    </FlexBox>

                    <Wrap marginTop={"8px"}>
                        <Text>{desc}</Text>
                    </Wrap>
                </Wrap>
            </Section.Content>
        </Section>
    );
}

Review.propTypes = {
    score: PropTypes.string.isRequired,
    userdesc: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    moddttm: PropTypes.string.isRequired
};

export default Review;
