/**
 * Convert date format for database
 * @see {@link https://stackoverflow.com/a/28149561/3216812}
 * @param  {Date}   date
 * @return {String}
 */
function convertDateFormatAsDB(date) {
    const timezoneOffset = new Date().getTimezoneOffset() * 60000;
    const timezoneDate = new Date(+new Date(date) - timezoneOffset);
    const [formattedDate] = timezoneDate.toISOString().replace("T", " ").split(".");

    return formattedDate;
}

export default convertDateFormatAsDB;
