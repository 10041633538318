export const ESTIMATE_STATUS = {
    100: "견적요청",
    200: "임시저장",
    300: "발송완료",
    400: "발송완료(상담)",
    450: "예약요청",
    500: "예약확정",
    550: "수리진행",
    600: "수리완료",
    800: "평가완료"
};

export const ESTIMATE_STATUS_MAP = {
    견적요청: 100,
    임시저장: 200,
    발송완료: 300,
    "발송완료(상담)": 400,
    예약요청: 450,
    예약확정: 500,
    수리진행: 550,
    수리완료: 600,
    평가완료: 800
};

/** @see {@link https://evergreen.segment.com/components/badge-and-pill} */
export const ESTIMATE_STATUS_COLOR = {
    100: "neutral",
    200: "teal",
    300: "orange",
    400: "teal",
    450: "red",
    500: "teal",
    550: "blue",
    600: "green",
    800: "green"
};

export const DUCKTIBLE = {
    A: "20%",
    B: "30%",
    20: "A",
    30: "B"
};

export const BTN_TEMPORARY_SAVE = "임시저장";
export const BTN_CONFIRM_ESTIMATE = "견적서 발송";
export const BTN_UPDATE_ESTIMATE = "견적서 수정";
export const BTN_CANCEL_BOOKED = "예약취소";
export const BTN_CONFIRM_BOOKED = "예약확정";
export const BTN_SEND_INVOICE = "확정견적서 발송";
export const BTN_CONFIRM_REPAIR_FINISH = "수리완료";
export const BTN_CONFIRM_PAID = "결제완료처리";

export const PAYMENT_MAP = {
    insurance: "01",
    cash: "02",
    "01": "insurance",
    "02": "cash"
};

export const INSURANCE_TEXT = {
    cash: "비보험",
    insurance: "보험"
};

export const CASH_FREE_SHOP = "무과금 업체";

export const AFFILIATE = {
    dbins: "DB 손해보험",
    hanains: "하나 손해보험",
    kbins: "KB 손해보험",
    muffin: "Tmap/Muffin"
};

export const INSURANCE_TYPE = {
    victim: "피해자",
    self: "자차"
};

export const EXTERNAL_SERVICE = {
    rentcar: "렌트",
    pickup: "픽업"
};
