import React from "react";
import PropTypes from "prop-types";
import Box from "ui-box";

function Emoji({ label, symbol }) {
    return (
        <Box is={"span"} role={"img"} aria-label={label || ""} aria-hidden={label ? "false" : "true"}>
            {symbol}
        </Box>
    );
}

Emoji.propTypes = {
    label: PropTypes.string,
    symbol: PropTypes.string
};

Emoji.defaultProps = {
    label: "",
    symbol: ""
};

export default Emoji;
