import React from "react";
import PropTypes from "prop-types";
import Box from "ui-box";
import cx from "classnames";
import { identity } from "ramda";
import css from "./flexbox.css";

// TODO remove unused props
function FlexBox({
    tag,
    className,
    children,
    row,
    col,
    center,
    middle,
    spaceBetween,
    spaceAround,
    style,
    onClick,
    ...restProps
}) {
    return (
        <Box
            display={"flex"}
            // TODO remove CSS later
            className={cx(css.flexbox, className, {
                [css.flexboxRow]: row,
                [css.flexboxCol]: col,
                [css.flexboxCenter]: center,
                [css.flexboxMiddle]: middle,
                [css.flexboxSpaceBetween]: spaceBetween,
                [css.flexboxSpaceAround]: spaceAround
            })}
            onClick={onClick}
            {...{
                ...restProps,
                // TODO combine it
                ...style
            }}
        >
            {children}
        </Box>
    );
}

FlexBox.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
    row: PropTypes.bool,
    col: PropTypes.bool,
    center: PropTypes.bool,
    middle: PropTypes.bool,
    spaceBetween: PropTypes.bool,
    spaceAround: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.shape({}),
    onClick: PropTypes.func
};

FlexBox.defaultProps = {
    tag: "div",
    className: "",
    row: false,
    col: false,
    center: false,
    middle: false,
    spaceBetween: false,
    spaceAround: false,
    children: "",
    style: {},
    onClick: identity
};

export default FlexBox;
