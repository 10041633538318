import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import css from "./image.css";

function Image({ src, alt, className }) {
    return <img src={src} alt={alt} className={cx(css.image, className)} />;
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string
};

Image.defaultProps = {
    alt: "",
    className: ""
};

export default Image;
