import { convertDateFormatAsDB } from "~/packages/utils";
import { initBookingInfoFields } from "./booking";
import {
    REQUEST_GET_SENT_EST_METADATA,
    SUCCESS_GET_SENT_EST_METADATA,
    FAILURE_GET_SENT_EST_METADATA,
    REQUEST_GET_EST_BY_SHOP,
    SUCCESS_GET_EST_BY_SHOP,
    FAILURE_GET_EST_BY_SHOP,
    REQUEST_GET_SHOP_EST,
    SUCCESS_GET_SHOP_EST,
    FAILURE_GET_SHOP_EST
} from "../actionTypes";

export function getSentEstMetadata() {
    return async (dispatch, getState, api) => {
        const {
            bootstrap: { reqid }
        } = getState();

        dispatch({
            type: REQUEST_GET_SENT_EST_METADATA
        });

        try {
            const {
                acc_req_no: accReqNo,
                insurance_corp: insuCorp,

                // TODO optimize later
                ...restRes
            } = await api.getSentEstMetadata(reqid);

            // NOTE could be null
            const { insuName = "" } = insuCorp || {};

            dispatch({
                type: SUCCESS_GET_SENT_EST_METADATA,
                payload: {
                    reqid,
                    insuName,
                    accReqNo,
                    ...restRes
                }
            });
        } catch (err) {
            dispatch({
                type: FAILURE_GET_SENT_EST_METADATA,
                payload: err.message
            });
        }
    };
}

export function getEstByShop() {
    return async (dispatch, getState, api) => {
        const {
            bootstrap: { reqid }
        } = getState();

        dispatch({
            type: REQUEST_GET_EST_BY_SHOP
        });

        try {
            const estList = await api.getEstByShop(reqid);

            dispatch({
                type: SUCCESS_GET_EST_BY_SHOP,
                payload: estList
            });
        } catch (err) {
            dispatch({
                type: FAILURE_GET_EST_BY_SHOP,
                payload: err
            });
        }
    };
}

export function getShopEst() {
    return async (dispatch, getState, api) => {
        const {
            bootstrap: { estid }
        } = getState();

        dispatch({
            type: REQUEST_GET_SHOP_EST
        });

        try {
            const est = await api.getShopEst(estid);

            const { bookdttm, bookmobile, bookusername } = est;

            dispatch({
                type: SUCCESS_GET_SHOP_EST,
                payload: est
            });

            const isBookingDateEmpty = bookdttm === "0000-00-00 00:00:00" || !bookdttm;
            const bookdttmField = isBookingDateEmpty ? convertDateFormatAsDB(new Date()) : bookdttm;

            dispatch(
                initBookingInfoFields({
                    bookdttm: bookdttmField,
                    estid,
                    bookmobile,
                    bookusername
                })
            );
        } catch (err) {
            dispatch({
                type: FAILURE_GET_SHOP_EST,
                payload: err
            });
        }
    };
}
