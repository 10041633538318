import { uniqByKey } from "~/utils";
import { REQUEST_GET_PORTFOLIO, SUCCESS_GET_PORTFOLIO, FAILURE_GET_PORTFOLIO } from "../actionTypes";

export function getPortfolio() {
    return async (dispatch, getState, api) => {
        const {
            bootstrap: { shopid },
            shop: { aftergalcnt },
            portfolio: { items: prevPortfolio, currentShopId, isLoading, nextPageCnt }
        } = getState();

        const isShopDiff = shopid !== currentShopId;
        const pageCnt = isShopDiff ? 1 : nextPageCnt + 1;
        const shouldProceed = (isShopDiff ? [] : prevPortfolio).length < parseInt(aftergalcnt, 10);

        if (shouldProceed && !isLoading) {
            dispatch({
                type: REQUEST_GET_PORTFOLIO,
                payload: {
                    shopid
                }
            });

            try {
                const portfolio = await api.getPortfolio({
                    pageCnt,
                    shopid
                });
                const hasMore = portfolio.length > 0;

                dispatch({
                    type: SUCCESS_GET_PORTFOLIO,
                    payload: {
                        currentShopId: shopid,
                        nextPageCnt: pageCnt,
                        portfolio: hasMore
                            ? uniqByKey("aftergalid", [...(isShopDiff ? [] : prevPortfolio), ...portfolio])
                            : prevPortfolio
                    }
                });
            } catch (err) {
                dispatch({
                    type: FAILURE_GET_PORTFOLIO,
                    payload: err
                });
            }
        }
    };
}
