import React from "react";
import PropTypes from "prop-types";
import Box from "ui-box";
import { identity } from "ramda";
import Strong from "../Strong";
import useTheme from "../theme";

function Button({ color, children, className, style, disabled, base, onClick }) {
    const theme = useTheme();

    const BTN_MAP = {
        // TODO decouple base
        base: {
            borderRadius: 0
        },

        night: {
            color: theme.color.white,
            backgroundColor: "#000"
        },

        outlined: {
            color: theme.color.gray2,
            border: "solid 1px #000"
        }
    };

    const styleProps = {
        border: "none",
        cursor: "pointer",
        padding: "17px",
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: theme.borderRadius,

        // TODO check out why I used this prop
        // color: theme.color[color],

        ...(disabled ? { backgroundColor: "#f4f4f4" } : BTN_MAP[color]),

        // TODO restProps
        ...style
    };

    return (
        <Box is={"button"} type={"button"} className={className} disabled={disabled} onClick={onClick} {...styleProps}>
            {base ? children : <Strong color={disabled ? "gray9" : BTN_MAP[color].color}>{children}</Strong>}
        </Box>
    );
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    base: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.shape({}),
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

Button.defaultProps = {
    className: "",
    base: false,
    color: "base",
    style: {},
    disabled: false,
    onClick: identity
};

export default Button;
