function fallbackCopy(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    const isCopied = document.execCommand("copy");

    if (isCopied) {
        // eslint-disable-next-line no-alert
        alert("클립보드에 복사되었습니다.");
    }

    document.body.removeChild(textArea);
}

function copyToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopy(text);
    } else {
        navigator.clipboard.writeText(text).then(() => {
            // eslint-disable-next-line no-alert
            alert("클립보드에 복사되었습니다.");
        });
    }
}

export default copyToClipboard;
