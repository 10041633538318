export const SAVE_PARSED_QUERIED = "SAVE_PARSED_QUERIED";
export const GENERATE_USERKEY = "GENERATE_USERKEY";
export const USERKEY_ERROR = "USERKEY_ERROR";
export const SAVE_USERKEY = "SAVE_USERKEY";
export const REQUEST_GET_TERMS = "REQUEST_GET_TERMS";
export const FAILURE_GET_TERMS = "FAILURE_GET_TERMS";
export const SUCCESS_GET_TERMS = "SUCCESS_GET_TERMS";
export const REQUEST_GET_PRIVACY = "REQUEST_GET_PRIVACY";
export const FAILURE_GET_PRIVACY = "FAILURE_GET_PRIVACY";
export const SUCCESS_GET_PRIVACY = "SUCCESS_GET_PRIVACY";
export const REQUEST_GET_3RD_PARTY = "REQUEST_GET_3RD_PARTY";
export const FAILURE_GET_3RD_PARTY = "FAILURE_GET_3RD_PARTY";
export const SUCCESS_GET_3RD_PARTY = "SUCCESS_GET_3RD_PARTY";

// est form
export const SAVE_BRAND_NAME = "SAVE_BRAND_NAME";
export const SAVE_ADDRESS = "SAVE_ADDRESS";
export const SAVE_EST_FIELDS_STEP1 = "SAVE_EST_FIELDS_STEP1";
export const SAVE_EST_FIELDS_STEP2 = "SAVE_EST_FIELDS_STEP2";
export const REQUEST_SUBMIT_EST_FORM = "REQUEST_SUBMIT_EST_FORM";
export const FAILURE_SUBMIT_EST_FORM = "FAILURE_SUBMIT_EST_FORM";
export const SUCCESS_SUBMIT_EST_FORM = "SUCCESS_SUBMIT_EST_FORM";
export const REQUEST_GET_CAR_INSURANCE_INFO = "REQUEST_GET_CAR_INSURANCE_INFO";
export const FAILURE_GET_CAR_INSURANCE_INFO = "FAILURE_GET_CAR_INSURANCE_INFO";
export const SUCCESS_GET_CAR_INSURANCE_INFO = "SUCCESS_GET_CAR_INSURANCE_INFO";
export const REQUEST_GET_CAR_BRAND_LIST = "REQUEST_GET_CAR_BRAND_LIST";
export const FAILURE_GET_CAR_BRAND_LIST = "FAILURE_GET_CAR_BRAND_LIST";
export const SUCCESS_GET_CAR_BRAND_LIST = "SUCCESS_GET_CAR_BRAND_LIST";
export const REQUEST_SEARCH_ADDRESS = "REQUEST_SEARCH_ADDRESS";
export const FAILURE_SEARCH_ADDRESS = "FAILURE_SEARCH_ADDRESS";
export const SUCCESS_SEARCH_ADDRESS = "SUCCESS_SEARCH_ADDRESS";
export const REQUEST_GET_IMAGE_IDS = "REQUEST_GET_IMAGE_IDS";
export const FAILURE_GET_IMAGE_IDS = "FAILURE_GET_IMAGE_IDS";
export const SUCCESS_GET_IMAGE_IDS = "SUCCESS_GET_IMAGE_IDS";
export const REQUEST_GET_LOCATION_IDS = "REQUEST_GET_LOCATION_IDS";
export const FAILURE_GET_LOCATION_IDS = "FAILURE_GET_LOCATION_IDS";
export const SUCCESS_GET_LOCATION_IDS = "SUCCESS_GET_LOCATION_IDS";
export const REQUEST_CHECK_VALID_PROMO_CODE = "REQUEST_CHECK_VALID_PROMO_CODE";
export const FAILURE_CHECK_VALID_PROMO_CODE = "FAILURE_CHECK_VALID_PROMO_CODE";
export const SUCCESS_CHECK_VALID_PROMO_CODE = "SUCCESS_CHECK_VALID_PROMO_CODE";

// view sent est
export const REQUEST_GET_SENT_EST_METADATA = "REQUEST_GET_SENT_EST_METADATA";
export const FAILURE_GET_SENT_EST_METADATA = "FAILURE_GET_SENT_EST_METADATA";
export const SUCCESS_GET_SENT_EST_METADATA = "SUCCESS_GET_SENT_EST_METADATA";
export const REQUEST_GET_EST_BY_SHOP = "REQUEST_GET_EST_BY_SHOP";
export const FAILURE_GET_EST_BY_SHOP = "FAILURE_GET_EST_BY_SHOP";
export const SUCCESS_GET_EST_BY_SHOP = "SUCCESS_GET_EST_BY_SHOP";

// view shop's est
export const REQUEST_GET_SHOP_EST = "REQUEST_GET_SHOP_EST";
export const FAILURE_GET_SHOP_EST = "FAILURE_GET_SHOP_EST";
export const SUCCESS_GET_SHOP_EST = "SUCCESS_GET_SHOP_EST";

// view shop details
export const REQUEST_GET_SHOP = "REQUEST_GET_SHOP";
export const FAILURE_GET_SHOP = "FAILURE_GET_SHOP";
export const SUCCESS_GET_SHOP = "SUCCESS_GET_SHOP";
export const REQUEST_GET_REVIEWS = "REQUEST_GET_REVIEWS";
export const FAILURE_GET_REVIEWS = "FAILURE_GET_REVIEWS";
export const SUCCESS_GET_REVIEWS = "SUCCESS_GET_REVIEWS";
export const REQUEST_GET_PORTFOLIO = "REQUEST_GET_PORTFOLIO";
export const FAILURE_GET_PORTFOLIO = "FAILURE_GET_PORTFOLIO";
export const SUCCESS_GET_PORTFOLIO = "SUCCESS_GET_PORTFOLIO";

// next API
export const REQUEST_JUMBOTRON_DATA = "REQUEST_JUMBOTRON_DATA";
export const FAILURE_JUMBOTRON_DATA = "FAILURE_JUMBOTRON_DATA";
export const SUCCESS_JUMBOTRON_DATA = "SUCCESS_JUMBOTRON_DATA";
export const REQUEST_CANCEL_BOOKING = "REQUEST_CANCEL_BOOKING";
export const FAILURE_CANCEL_BOOKING = "FAILURE_CANCEL_BOOKING";
export const SUCCESS_CANCEL_BOOKING = "SUCCESS_CANCEL_BOOKING";
export const REQUEST_REQUEST_BOOKING = "REQUEST_REQUEST_BOOKING";
export const FAILURE_REQUEST_BOOKING = "FAILURE_REQUEST_BOOKING";
export const SUCCESS_REQUEST_BOOKING = "SUCCESS_REQUEST_BOOKING";
export const REQUEST_UPDATE_BOOKING = "REQUEST_UPDATE_BOOKING";
export const FAILURE_UPDATE_BOOKING = "FAILURE_UPDATE_BOOKING";
export const SUCCESS_UPDATE_BOOKING = "SUCCESS_UPDATE_BOOKING";
export const INIT_BOOKING_INFO_FIELDS = "INIT_BOOKING_INFO_FIELDS";
export const UPDATE_BOOKING_INFO_FIELD = "UPDATE_BOOKING_INFO_FIELD";
