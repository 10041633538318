import * as types from "../actionTypes";

const initialState = {
    form: {
        estid: "",
        bookdttm: "",
        bookusername: "",
        bookmobile: ""
    },
    errors: {
        getCarBrandList: null
    }
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.INIT_BOOKING_INFO_FIELDS: {
            return {
                ...state,
                form: payload
            };
        }

        case types.UPDATE_BOOKING_INFO_FIELD: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [payload.name]: payload.value
                }
            };
        }

        case types.REQUEST_CANCEL_BOOKING:
        case types.REQUEST_REQUEST_BOOKING:
        case types.REQUEST_UPDATE_BOOKING: {
            return {
                ...state,
                error: null
            };
        }

        case types.FAILURE_CANCEL_BOOKING:
        case types.FAILURE_REQUEST_BOOKING:
        case types.FAILURE_UPDATE_BOOKING: {
            return {
                ...state,
                error: payload
            };
        }

        case types.SUCCESS_CANCEL_BOOKING:
        case types.SUCCESS_REQUEST_BOOKING:
        case types.SUCCESS_UPDATE_BOOKING: {
            return {
                ...state,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
