import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ko from "javascript-time-ago/locale/ko";
import { ThemeContext, theme } from "~/packages/ui/mobile";
import store from "~/store";
import App from "~/App";
import "~/styles/app.css";
import { AmplitudeContext, amplitude } from "./amplitude";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ko);

const { AMPLITUDE_SDK_API } = process.env;
const userId = localStorage.getItem("userkey");
amplitude.init(AMPLITUDE_SDK_API, userId);

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <ThemeContext.Provider value={theme}>
                <AmplitudeContext.Provider value={amplitude}>
                    <App />
                </AmplitudeContext.Provider>
            </ThemeContext.Provider>
        </Router>
    </Provider>,
    document.getElementById("app-root")
);
