import qs from "querystring";
import { POST_FILE_UPLOADS } from "./endpoint";
import instance from "./instance";

export function get(args) {
    let url = args;
    let query = {};
    let options = {};

    if (typeof args === "object") {
        url = args.url;
        query = args.query || {};
        options = args.options || {};
    }

    const stringifiedQuery = qs.stringify(query);

    return instance.get(`${url}${stringifiedQuery ? `?` : ""}${stringifiedQuery}`, options);
}

export function post(url, options = {}) {
    return instance.post(url, options);
}

export function upload(formData) {
    return instance.post(POST_FILE_UPLOADS, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

export default instance;
export { external } from "./instance";
