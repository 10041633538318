import React, { useState } from "react";
import PropTypes from "prop-types";
import { compose } from "ramda";
import { Section, Text, Wrap, FlexBox, ImageViewer, Portal } from "~/packages/ui/mobile";
import { applyCurrency } from "~/packages/utils";
import { parseInteger } from "~/utils";

const modalRoot = document.getElementById("modal-root");
const div = document.createElement("div");

function Portfolio({ data }) {
    const [fileList, setFileList] = useState([]);

    return (
        <>
            <Section>
                <Section.Content>
                    {/* TODO <Grid /> */}
                    <Wrap
                        display={"grid"}
                        padding={"24px 20px"}
                        backgroundColor={"#fff"}
                        gridTemplateColumns={"repeat(auto-fit, minmax(160px, 1fr))"}
                        gridGap={"15px"}
                    >
                        {data.map(({ aftergalid, files, brand, cardesc, parts, repairprice }) => {
                            const issues = parts.map(({ code_name: codeName }) => codeName).join(", ");

                            // TODO card component
                            return (
                                <FlexBox
                                    key={aftergalid}
                                    col
                                    spaceBetween
                                    style={{
                                        backgroundImage: `url(${files[0].thumb_w456})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        borderRadius: "16px",
                                        height: "160px",
                                        color: "#fff",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        setFileList(files);
                                    }}
                                >
                                    <Wrap position={"relative"}>
                                        <Text
                                            color={"gray"}
                                            size={"small"}
                                            weight={"bold"}
                                            type={"inline"}
                                            style={{
                                                position: "absolute",
                                                top: "13px",
                                                left: "10px",
                                                backgroundColor: "#fff",
                                                paddingTop: "4px",
                                                paddingBottom: "4px",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                borderRadius: "14px",
                                                border: "1px solid #000"
                                            }}
                                        >
                                            {brand.brandnameko}
                                        </Text>
                                    </Wrap>

                                    <Wrap
                                        padding={"8px 16px"}
                                        height={"72px"}
                                        backgroundColor={"rgba(0, 0, 0, 0.5)"}
                                        borderRadius={"0 0 16px 16px"}
                                    >
                                        <Text size={"small"} weight={"bold"}>
                                            {cardesc}
                                        </Text>
                                        <Text size={"smaller"}>{issues}</Text>
                                        <Text size={"smaller"}>
                                            {repairprice
                                                ? compose(
                                                      applyCurrency.withOptions({
                                                          prependUnit: true
                                                      }),
                                                      parseInteger
                                                  )(repairprice)
                                                : ""}
                                        </Text>
                                        )
                                    </Wrap>
                                </FlexBox>
                            );
                        })}
                    </Wrap>
                </Section.Content>
            </Section>

            {!!fileList.length && (
                <Portal rootEl={modalRoot} el={div}>
                    <ImageViewer
                        files={fileList}
                        onClose={() => {
                            setFileList([]);
                        }}
                    />
                </Portal>
            )}
        </>
    );
}

Portfolio.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            aftergalid: PropTypes.string,
            files: PropTypes.arrayOf(
                PropTypes.shape({
                    thumb_w456: PropTypes.string
                })
            ),
            brand: PropTypes.shape({
                brandnameko: PropTypes.string
            }),
            cardesc: PropTypes.string,
            parts: PropTypes.arrayOf(
                PropTypes.shape({
                    code_name: PropTypes.string
                })
            ),
            repairprice: PropTypes.string
        })
    ).isRequired
};

export default Portfolio;
