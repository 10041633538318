import React from "react";
import PropTypes from "prop-types";
import Box from "ui-box";

function Form({ children }) {
    return <Box is={"form"}>{children}</Box>;
}

Form.propTypes = {
    children: PropTypes.node.isRequired
};

export default Form;
