import { groupBy } from "ramda";
import { extractInner } from "~/utils";
import * as types from "../actionTypes";

export function getCarBrandList(options = {}) {
    return async (dispatch, getState, api) => {
        dispatch({
            type: types.REQUEST_GET_CAR_BRAND_LIST
        });

        try {
            const { allList = [], topList = [] } = await api.getBrandList(options);

            dispatch({
                type: types.SUCCESS_GET_CAR_BRAND_LIST,
                payload: {
                    byLetter: groupBy(({ first }) => first, allList),
                    topList,
                    allList
                }
            });
        } catch (err) {
            dispatch({
                type: types.FAILURE_GET_CAR_BRAND_LIST,
                payload: err
            });
        }
    };
}

export function getTermsService() {
    return async (dispatch, getState, api) => {
        dispatch({
            type: types.REQUEST_GET_TERMS
        });

        try {
            const term = await api.getTermsService();

            dispatch({
                type: types.SUCCESS_GET_TERMS,
                payload: extractInner(term)
            });
        } catch (err) {
            dispatch({
                type: types.FAILURE_GET_TERMS,
                payload: err
            });
        }
    };
}

export function getPrivacyPolicy() {
    return async (dispatch, getState, api) => {
        dispatch({
            type: types.REQUEST_GET_PRIVACY
        });

        try {
            const privacy = await api.getPrivacyPolicy();

            dispatch({
                type: types.SUCCESS_GET_PRIVACY,
                payload: extractInner(privacy)
            });
        } catch (err) {
            dispatch({
                type: types.FAILURE_GET_PRIVACY,
                payload: err
            });
        }
    };
}

export function get3rdParty() {
    return async (dispatch, getState, api) => {
        dispatch({
            type: types.REQUEST_GET_3RD_PARTY
        });

        try {
            const content = await api.get3rdParty();

            dispatch({
                type: types.SUCCESS_GET_3RD_PARTY,
                payload: extractInner(content)
            });
        } catch (err) {
            dispatch({
                type: types.FAILURE_GET_3RD_PARTY,
                payload: err
            });
        }
    };
}
