import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import css from "./tab-panel.css";

function TabPanel({ currentTab, children, idx }) {
    return (
        <div
            className={cx(css.tabPanel, {
                "is-hidden": currentTab !== idx
            })}
        >
            {children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    currentTab: PropTypes.number,
    idx: PropTypes.number
};

TabPanel.defaultProps = {
    currentTab: PropTypes.node.isRequired,
    idx: 0
};

export default TabPanel;
