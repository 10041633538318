import { connect } from "react-redux";
import { ReqEstStep2 } from "~/pages";
import {
    getTermsService,
    getPrivacyPolicy,
    saveAddress,
    saveEstFieldsStep2,
    getImageIds,
    getLocationIds,
    submitEstForm
} from "~/store/actions";
import { getAddressList } from "~/services/api";

function mapStateToProps({
    form: { carImages, carImageDesc, phoneNo, addressNameA, addressA, addressNameB, agreement },
    assets: { terms, privacy }
}) {
    return {
        carImages,
        carImageDesc,
        phoneNo,
        addressNameA,
        addressA,
        addressNameB,
        agreement,
        terms,
        privacy
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveEstFieldsStep2: fields => dispatch(saveEstFieldsStep2(fields)),
        getImageIds: (images = []) => dispatch(getImageIds(images)),
        getLocationIds: () => dispatch(getLocationIds()),
        saveAddress: name => selected => dispatch(saveAddress({ name, value: selected })),
        submitEstForm: () => dispatch(submitEstForm()),
        getTermsService: () => dispatch(getTermsService()),
        getPrivacyPolicy: () => dispatch(getPrivacyPolicy())
    };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        searchAddress: async ({ value, limit = 5 }) => {
            const keyword = encodeURIComponent(value);
            const addressList = await getAddressList({ keyword, limit });

            return addressList;
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ReqEstStep2);
