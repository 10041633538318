import React from "react";
import PropTypes from "prop-types";
import Box from "ui-box";
import cx from "classnames";
import css from "./divider.css";

function Divider({ type, forText, color }) {
    const _type = {
        solid: css.dividerSolid,
        dotted: css.dividerDotted,
        dashed: css.dividerDashed
    };

    return (
        <Box
            className={cx(css.divider, _type[type] || _type.solid, {
                [css.dividerMargin]: forText,
                [css.dividerBlack]: color === "black"
            })}
        />
    );
}

Divider.propTypes = {
    type: PropTypes.string,
    forText: PropTypes.bool,
    color: PropTypes.string
};

Divider.defaultProps = {
    type: "solid",
    forText: false,
    color: ""
};

export default Divider;
