import { isNil } from "ramda";

function createApplyCurrency({ prependUnit = false, locale = "ko-KR", currency = "KRW" } = {}) {
    return currencyNumber => {
        if (currencyNumber === "" || isNil(currencyNumber)) {
            return currencyNumber;
        }

        let parsedCurrency = currencyNumber;

        if (typeof parsedCurrency !== "number") {
            parsedCurrency = parseInt(parsedCurrency, 10);
        }

        if (Number.isNaN(parsedCurrency)) {
            throw new Error("invalid currency!");
        }

        const origCurrency = parsedCurrency.toLocaleString(locale, {
            style: "currency",
            currency
        });

        return prependUnit ? origCurrency : origCurrency.substr(1);
    };
}

const applyCurrency = createApplyCurrency();
applyCurrency.withOptions = createApplyCurrency;

export default applyCurrency;
