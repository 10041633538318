import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import useReduxForm from "use-redux-form";
import Box from "ui-box";
import { withErrorBoundary } from "@sentry/react";
import qs from "query-string";
import { MREQ_AMPLITUDE } from "__presets";
import { Button, FlexBox, Gap, Partial, Screen, SelectBox, TextInputField } from "~/packages/ui/mobile";
import { Jumbotron } from "~/components";
import { BRAND_LIST, REQ_EST_STEP2, VIEW_RECEIVED } from "../../routes";
import useAmplitude from "../../amplitude";

const PROMOSION_LOGO_MAP = {
    DBINS: [
        import("~/assets/imgs/db-cardoc.png"),
        import("~/assets/imgs/db-cardoc@2x.png"),
        import("~/assets/imgs/db-cardoc@3x.png")
    ],
    KBINS: [
        import("~/assets/imgs/kb-cardoc.png"),
        import("~/assets/imgs/kb-cardoc@2x.png"),
        import("~/assets/imgs/kb-cardoc@3x.png")
    ],
    HANAINS: [
        import("~/assets/imgs/hana-cardoc.png"),
        import("~/assets/imgs/hana-cardoc@2x.png"),
        import("~/assets/imgs/hana-cardoc@3x.png")
    ],
    SHINHAN: [
        import("~/assets/imgs/sh-cardoc.png"),
        import("~/assets/imgs/sh-cardoc@2x.png"),
        import("~/assets/imgs/sh-cardoc@3x.png")
    ]
};

function ReqEstStep1({
    jumbotron,
    promocode,
    insuName,
    setNavToolbar,
    initialize,
    saveEstFieldsStep1,
    checkValidPromoCode,
    requestList
}) {
    const history = useHistory();
    const location = useLocation();

    const amplitude = useAmplitude();
    const { isValidated, getFieldProps, handleSubmit } = useReduxForm({
        storePath: "form",

        validate: values => {
            const errors = {};

            if (!values.ownerName) {
                errors.ownerName = "isRequired";
            }

            if (!values.plateNo) {
                errors.plateNo = "isRequired";
            }

            if (!values.carBrand) {
                errors.carBrand = "isRequired";
            }

            if (!values.car) {
                errors.car = "isRequired";
            }

            if (!values.year) {
                errors.year = "isRequired";
            } else if (values.year.length !== 4) {
                errors.year = "Should be 4-digit year";
            }

            return errors;
        },

        onSubmit: ({ isInvalid }) => {
            checkValidPromoCode(() => {
                if (!isInvalid) {
                    history.push(REQ_EST_STEP2);
                }
            });
        },

        onChange: saveEstFieldsStep1
    });

    useEffect(() => {
        if (promocode) {
            saveEstFieldsStep1({ name: "promotioncode", value: promocode });

            Promise.all(PROMOSION_LOGO_MAP[promocode]).then(imgs => {
                const [img, img2x, img3x] = imgs;

                setNavToolbar({
                    title: (
                        <Box
                            is={"img"}
                            height={30}
                            width={240}
                            srcSet={`${img.default}, ${img2x.default} 2x, ${img3x.default} 3x`}
                            src={img.default}
                        />
                    ),
                    left: null
                });
            });
        }
    }, [promocode, setNavToolbar, saveEstFieldsStep1]);

    useEffect(() => {
        if (!insuName) {
            initialize(history.push);
        }
    }, [insuName, initialize, history]);

    useEffect(() => {
        if (!requestList) {
            return;
        }
        const query = qs.parse(location.search);
        const hasNoredirect = Object.keys(query).includes("noredirect");

        if (!hasNoredirect && requestList.length > 0) {
            history.replace(`${VIEW_RECEIVED}?reqid=${requestList[0].reqid}`);
        }
    }, [history, location.search, requestList]);

    const isInsurer = insuName && promocode;

    return (
        <Screen.Next hasNavi stretch>
            <Jumbotron topBanner={jumbotron.topBanner} reviews={jumbotron.reviews} />
            <Gap />
            <Partial is={"form"} backgroundColor={"#fff"}>
                <Box marginBottom={"45px"}>
                    {isInsurer && (
                        <TextInputField
                            label={"제휴사 접수번호"}
                            readOnly
                            {...getFieldProps("insurerName", {
                                isRequired: true,
                                exclude: ["isInvalid"]
                            })}
                        />
                    )}

                    <FlexBox flexDirection={"row"}>
                        <TextInputField
                            label={"소유자명"}
                            placeholder={"예) 김카닥"}
                            marginRight={"7px"}
                            {...getFieldProps("ownerName", {
                                isRequired: true,
                                exclude: ["isInvalid"]
                            })}
                            isRequired
                        />

                        <TextInputField
                            label={"차량번호"}
                            placeholder={"예) 123가1234"}
                            {...getFieldProps("plateNo", {
                                isRequired: true,
                                exclude: ["isInvalid"]
                            })}
                            isRequired
                        />
                    </FlexBox>

                    <SelectBox
                        label={"차량 브랜드"}
                        placeholder={"선택"}
                        onClick={() => history.push(BRAND_LIST)}
                        {...getFieldProps("carBrand", {
                            isRequired: true,
                            exclude: ["isInvalid"]
                        })}
                        isRequired
                    />

                    <FlexBox flexDirection={"row"}>
                        <TextInputField
                            label={"차량명"}
                            placeholder={"예) 그랜저"}
                            marginRight={"7px"}
                            {...getFieldProps("car", {
                                isRequired: true,
                                exclude: ["isInvalid"]
                            })}
                            isRequired
                        />

                        <TextInputField
                            label={"연식"}
                            type={"number"}
                            placeholder={"예) 2020"}
                            {...getFieldProps("year", {
                                isRequired: true,
                                exclude: ["isInvalid"]
                            })}
                            isRequired
                        />
                    </FlexBox>

                    {!promocode && (
                        <TextInputField
                            label={"프로모션 코드"}
                            {...getFieldProps("promotioncode", {
                                isRequired: true,
                                exclude: ["isInvalid"]
                            })}
                            isRequired
                        />
                    )}
                </Box>

                <Button
                    color={"night"}
                    disabled={!isValidated}
                    onClick={() => {
                        amplitude.logEvent(MREQ_AMPLITUDE.completeMreqOwnerInfo);
                        handleSubmit();
                    }}
                >
                    다음
                </Button>
            </Partial>
        </Screen.Next>
    );
}

ReqEstStep1.propTypes = {
    setNavToolbar: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    saveEstFieldsStep1: PropTypes.func.isRequired,
    checkValidPromoCode: PropTypes.func.isRequired,
    promocode: PropTypes.string,
    insuName: PropTypes.string,
    jumbotron: PropTypes.shape({
        topBanner: PropTypes.shape({}),
        reviews: PropTypes.arrayOf(PropTypes.shape())
    }),
    requestList: PropTypes.arrayOf(PropTypes.object)
};

ReqEstStep1.defaultProps = {
    promocode: "",
    insuName: "",
    jumbotron: {},
    requestList: []
};

export default withErrorBoundary(ReqEstStep1, {
    fallback: "an error has occurred"
});
