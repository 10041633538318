import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import css from "./not-yet.css";
import Text from "../Text";
import Emoji from "../Emoji";

function NotYet({ children, label, emoji }) {
    return (
        <Text type={"inline"} className={cx(css.notYet)}>
            {children} <Emoji label={label} symbol={emoji} />
        </Text>
    );
}

NotYet.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string,
    emoji: PropTypes.string
};

NotYet.defaultProps = {
    children: "견적서 작성을 준비중입니다!‍",
    label: "man mechanic",
    emoji: "👨‍🔧"
};

export default NotYet;
