import { withErrorBoundary } from "@sentry/react";
import PropTypes from "prop-types";
import { identity } from "ramda";
import React, { useEffect, useState } from "react";
import { MREQ_AMPLITUDE } from "__presets";
import {
    Autosuggest,
    Button,
    CheckBox,
    Screen,
    Section,
    Text,
    TextBox,
    TextField,
    UploadImage,
    Wrap
} from "~/packages/ui/mobile";
import { useForm } from "~/utils/hooks";
import useAmplitude from "../../amplitude";

function ReqEstStep2({
    setNavToolbar,
    carImages,
    carImageDesc,
    phoneNo,
    addressNameA,
    addressA,
    addressNameB,
    agreement,
    saveEstFieldsStep2,
    searchAddress,
    saveAddress,
    getImageIds,
    getLocationIds,
    submitEstForm
}) {
    const amplitude = useAmplitude();
    const { isValidated, isSubmitting, getFieldProps, handleSubmit, formState } = useForm({
        initialValues: {
            carImages,
            carImageDesc,
            phoneNo,
            addressNameA,
            addressNameB,
            agreement
        },
        validate: values => {
            // TODO create api for optional values
            const invalidValues = Object.keys(values).filter(key => {
                if (key.search(/addressNameB|reqRentCar|reqPickup/) > -1) {
                    return "";
                }

                if (Object.keys(addressA).length === 0) {
                    return {
                        addressA: "please select address"
                    };
                }

                const val = values[key];

                return Array.isArray(val) ? !val.length : !val;
            });

            return !invalidValues.length;
        },
        onChange: saveEstFieldsStep2,
        onSubmit: async values => {
            await getImageIds(values.carImages);
            await getLocationIds();
            await submitEstForm();
        }
    });

    const [openModalType, setOpenModalType] = useState("");

    useEffect(() => {
        setNavToolbar({
            title: "견적요청하기 (2/2)",
            left: "back"
        });

        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [setNavToolbar]);

    const handleUpdate = async (value, limit) => {
        let addressList = [];

        if (value) {
            addressList = await searchAddress({ value, limit });
        }

        return addressList;
    };

    return (
        <>
            <Screen hasNavi stretch>
                <Section>
                    <Section.Content>
                        <UploadImage
                            placeholder={["손상부위 사진을 등록하면", "수리 업체로부터 견적을 받아볼 수 있습니다."]}
                            onUpoadLog={() => amplitude.logEvent(MREQ_AMPLITUDE.clickMreqAddImage)}
                            {...getFieldProps("carImages")}
                        />

                        <Wrap padding={"0 20px 24px"}>
                            <TextBox
                                placeholder={[
                                    "어떤 부위가 어떻게 손상되었나요?",
                                    "(예: 운전석 앞 문이 내부까지 찌그러졌습니다.)"
                                ]}
                                {...getFieldProps("carImageDesc")}
                                isRequired
                            >
                                사진 상세 설명
                            </TextBox>
                        </Wrap>
                    </Section.Content>
                </Section>

                <Section>
                    <Section.Content>
                        <Wrap padding={"24px 20px"}>
                            <TextField
                                placeholder={"(-) 없이 숫자만 입력하세요."}
                                {...getFieldProps("phoneNo")}
                                isRequired
                            >
                                휴대폰 번호
                            </TextField>
                        </Wrap>
                    </Section.Content>
                </Section>

                <Section>
                    <Section.Content>
                        <Wrap padding={"24px 20px"}>
                            <Text size={"small"} color={"gray"} weight={"bold"}>
                                위치 설정*
                            </Text>

                            <Autosuggest
                                placeholder={"첫 번째 설정할 동(읍/면)을 입력하세요."}
                                {...getFieldProps("addressNameA")}
                                onSelect={saveAddress("addressA")}
                                limit={5}
                                onUpdate={handleUpdate}
                                onBlur={() => {
                                    if (Object.keys(addressA).length === 0) {
                                        getFieldProps("addressNameA").onChange("");
                                    }
                                }}
                            />

                            <Autosuggest
                                placeholder={"두 번째 설정할 동(읍/면)을 입력하세요."}
                                {...getFieldProps("addressNameB")}
                                onSelect={saveAddress("addressB")}
                                limit={5}
                                onUpdate={handleUpdate}
                            />
                        </Wrap>
                    </Section.Content>
                </Section>

                <Section>
                    <Section.Content>
                        <Wrap padding={"24px 20px"}>
                            <Text size={"small"} color={"gray"} weight={"bold"}>
                                추가 요청사항 (선택)
                            </Text>

                            <CheckBox label={"렌터카"} {...getFieldProps("reqRentCar")} />
                            <CheckBox label={"픽업서비스"} {...getFieldProps("reqPickup")} />
                        </Wrap>
                    </Section.Content>
                </Section>

                <Section>
                    <Section.Content>
                        <Wrap padding={"24px 20px"}>
                            <CheckBox
                                label={[
                                    <>
                                        <Text
                                            type={"inline"}
                                            onClick={() => setOpenModalType("privacy-policy")}
                                            style={{ textDecoration: "underline" }}
                                        >
                                            서비스 이용약관
                                        </Text>{" "}
                                        및
                                    </>,
                                    <>
                                        <Text
                                            type={"inline"}
                                            onClick={() => setOpenModalType("user-terms")}
                                            style={{ textDecoration: "underline" }}
                                        >
                                            개인정보 취급방침
                                        </Text>
                                        에 동의합니다.
                                    </>
                                ]}
                                {...getFieldProps("agreement")}
                            />

                            <Wrap marginTop={"64px"}>
                                <Button
                                    color={"night"}
                                    disabled={!isValidated || isSubmitting}
                                    onClick={() => {
                                        amplitude.logEvent(MREQ_AMPLITUDE.completeMreqRequest);
                                        handleSubmit();
                                    }}
                                >
                                    견적요청하기
                                </Button>
                            </Wrap>
                        </Wrap>
                    </Section.Content>
                </Section>
            </Screen>

            {!!openModalType && (
                <div
                    style={{
                        width: "100vw",
                        height: "100vh",
                        position: "fixed",
                        background: "white",
                        top: 0,
                        left: 0,
                        zIndex: 1000
                    }}
                >
                    <button
                        onClick={() => setOpenModalType("")}
                        style={{
                            fontSize: "32px",
                            background: "none",
                            border: "none",
                            position: "fixed",
                            zIndex: 10000,
                            padding: "10px",
                            top: "10px",
                            right: "10px"
                        }}
                    >
                        x
                    </button>
                    <iframe
                        src={`${process.env.API_WEBVIEW_URL}/terms/cardoc-app/${openModalType}/latest`}
                        width={"100%"}
                        height={"100%"}
                        style={{ border: "none", padding: "10px" }}
                    />
                </div>
            )}
        </>
    );
}

ReqEstStep2.propTypes = {
    setNavToolbar: PropTypes.func.isRequired,
    carImages: PropTypes.arrayOf(PropTypes.shape({})),
    carImageDesc: PropTypes.string,
    phoneNo: PropTypes.string,
    addressNameA: PropTypes.string,
    addressA: PropTypes.shape({}),
    addressNameB: PropTypes.string,
    agreement: PropTypes.bool,
    terms: PropTypes.string,
    privacy: PropTypes.string,
    saveEstFieldsStep2: PropTypes.func,
    searchAddress: PropTypes.func,
    getImageIds: PropTypes.func,
    saveAddress: PropTypes.func,
    getLocationIds: PropTypes.func,
    submitEstForm: PropTypes.func,
    getTermsService: PropTypes.func,
    getPrivacyPolicy: PropTypes.func
};

ReqEstStep2.defaultProps = {
    carImages: [],
    carImageDesc: "",
    phoneNo: "",
    addressNameA: "",
    addressA: {},
    addressNameB: "",
    terms: "",
    privacy: "",
    agreement: false,
    saveEstFieldsStep2: identity,
    searchAddress: identity,
    getImageIds: identity,
    saveAddress: identity,
    getLocationIds: identity,
    submitEstForm: identity,
    getTermsService: identity,
    getPrivacyPolicy: identity
};

export default withErrorBoundary(ReqEstStep2, {
    fallback: "an error has occurred"
});
