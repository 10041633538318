import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "ramda";
import { withErrorBoundary } from "@sentry/react";
import { Carousel, CheckBox, Screen, Section, Text, TextBox, TextField, Wrap } from "~/packages/ui/mobile";
import { useForm } from "~/utils/hooks";

function ViewSentEst({ setNavToolbar, sentEst }) {
    useEffect(() => {
        setNavToolbar({
            title: "견적요청서",
            left: "back"
        });
    }, []);

    const [addressNameA, addressNameB] = sentEst.locations.map(({ representation }) => representation);

    const { getFieldProps } = useForm({
        initialValues: {
            carImageDesc: sentEst.etcdesc,
            insuOwner: sentEst.insu_owner,
            insuInfo: `${sentEst.insuName} 접수번호 : ${sentEst.accReqNo}`,
            phoneNo: sentEst.phonenumber,
            addressNameA,
            addressNameB
        }
    });

    if (isEmpty(sentEst.locations)) {
        // no data yet or illegal access
        return "";
    }

    return (
        <Screen hasNavi stretch>
            <Section>
                <Section.Content>
                    <Carousel
                        list={sentEst.files.map(({ url }) => url)}
                        caption={{
                            logo: (
                                <img
                                    src={sentEst.brand.imgUrl}
                                    width={"50px"}
                                    height={"50px"}
                                    alt={sentEst.carbrandname}
                                />
                            ),
                            name: `${sentEst.carbrandname} ${sentEst.cardesc}`,
                            plateNumber: sentEst.platenum
                        }}
                    />

                    <Wrap padding={"0 20px 24px"}>
                        <TextBox
                            placeholder={
                                "사진 상세 설명 영역입니다. 현재는 수정 불가능 하나 추후에 수정할 수 있도록 해야 합니다. 텍스트 영역의 상하좌우 마진은 항상 16px 을 유지합니다."
                            }
                            {...getFieldProps("carImageDesc")}
                            readOnly
                        >
                            사진 상세설명
                        </TextBox>
                    </Wrap>
                </Section.Content>
            </Section>

            <Section>
                <Section.Content>
                    <Wrap padding={"24px 20px"}>
                        <TextField {...getFieldProps("phoneNo")} readOnly>
                            휴대폰 번호
                        </TextField>
                        <TextField {...getFieldProps("insuOwner")} readOnly>
                            소유자명
                        </TextField>
                        <TextField {...getFieldProps("insuInfo")} readOnly>
                            제휴사 정보
                        </TextField>
                    </Wrap>
                </Section.Content>
            </Section>

            <Section>
                <Section.Content>
                    <Wrap padding={"24px 20px"}>
                        <Text size={"small"} color={"gray"} weight={"bold"}>
                            위치 설정
                        </Text>
                        <TextField {...getFieldProps("addressNameA")} readOnly />
                        <TextField {...getFieldProps("addressNameB")} readOnly />
                    </Wrap>
                </Section.Content>
            </Section>

            <Section>
                <Section.Content>
                    <Wrap padding={"24px 20px"}>
                        <Text size={"small"} color={"gray"} weight={"bold"}>
                            추가 요청사항 (선택)
                        </Text>

                        <CheckBox label={"보험수리"} checked readOnly />
                        <CheckBox label={"렌터카"} checked={sentEst.rentcaryn === "Y"} readOnly />
                        <CheckBox label={"픽업서비스"} checked={sentEst.pickupyn === "Y"} readOnly />
                    </Wrap>
                </Section.Content>
            </Section>
        </Screen>
    );
}

ViewSentEst.propTypes = {
    setNavToolbar: PropTypes.func.isRequired,
    sentEst: PropTypes.shape({
        accReqNo: PropTypes.string.isRequired,
        etcdesc: PropTypes.string.isRequired,
        insu_owner: PropTypes.string.isRequired,
        insuName: PropTypes.string.isRequired,
        phonenumber: PropTypes.string.isRequired,
        carbrandname: PropTypes.string.isRequired,
        cardesc: PropTypes.string.isRequired,
        platenum: PropTypes.string.isRequired,
        pickupyn: PropTypes.string.isRequired,
        rentcaryn: PropTypes.string.isRequired,
        brand: PropTypes.shape({
            imgUrl: PropTypes.string.isRequired
        }).isRequired,
        files: PropTypes.arrayOf({
            url: PropTypes.string.isRequired
        }).isRequired,
        locations: PropTypes.arrayOf(
            PropTypes.shape({
                representation: PropTypes.string.isRequired
            })
        ).isRequired
    })
};

ViewSentEst.defaultProps = {
    sentEst: {
        files: [],
        locations: []
    }
};

export default withErrorBoundary(ViewSentEst, {
    fallback: "an error has occurred"
});
