import React from "react";
import PropTypes from "prop-types";
import Box from "ui-box";
import FlexBox from "../FlexBox";
import Divider from "../Divider";

// TODO refactor later
function Modal({ isShown, isDivider, title, content, buttons, padding }) {
    if (!isShown) {
        return "";
    }

    return (
        <Box position={"fixed"} top={0} left={0} right={0} bottom={0} zIndex={999}>
            <Box width={"100%"} height={"100%"} opacity={0.6} backgroundColor={"#000"} />

            <Box
                position={"fixed"}
                backgroundColor={"#fff"}
                top={"50%"}
                left={"50%"}
                transform={"translate(-50%, -50%)"}
                width={"calc(100% - 40px)"}
                borderRadius={16}
                padding={padding}
            >
                {title && (
                    <FlexBox alignItems={"center"} justifyContent={"center"} col>
                        {title}
                    </FlexBox>
                )}

                {isDivider && (
                    <Box marginY={16}>
                        <Divider />
                    </Box>
                )}

                <FlexBox alignItems={"center"} col>
                    {content}
                </FlexBox>

                <FlexBox row>{buttons}</FlexBox>
            </Box>
        </Box>
    );
}

Modal.propTypes = {
    content: PropTypes.node.isRequired,
    isShown: PropTypes.bool,
    isDivider: PropTypes.bool,
    title: PropTypes.node,
    buttons: PropTypes.node,
    padding: PropTypes.number
};

Modal.defaultProps = {
    isShown: false,
    isDivider: false,
    title: "",
    buttons: "",
    padding: 18
};

export default Modal;
