import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { compose, split, reverse, add } from "ramda";
import Box from "ui-box";
import { getDateString, padZeros, intervalByMins } from "~/packages/utils";
import css from "./time-selection.css";
import sheetBar from "./sheet-bar.png";
import Button from "../Button";
import Paragraph from "../Paragraph";
import FlexBox from "../FlexBox";
import Strong from "../Strong";
import { DropDown, StopWatch } from "../../svg";

const { body: $body } = document;

const MERIDIEM = ["오전", "오후"];
const HOURS = [...Array(12).keys()].map(add(1));
const MINS = ["00", "10", "20", "30", "40", "50"];

const fillEmptyToScroll = items => {
    return [null, ...items, null];
};

const getSelectedDataUnit = date => {
    const [time, meridiem] = compose(reverse, split(" "), getDateString)(date);
    const [hour, min] = time.split(":");

    return [meridiem, parseInt(hour, 10), min];
};

const getIntervalMin = (interval, min) => {
    const nextMin = intervalByMins(interval, min);

    return nextMin === "60" ? "00" : `${nextMin}`;
};

function TimeSelection({ selectedDate, isShown, setIsShown, onConfirm, isEditable }) {
    const [meridiem, hour, min] = getSelectedDataUnit(selectedDate);
    const refinedMin = getIntervalMin(10, min);
    const timeTxt = `${meridiem} ${padZeros(2, hour)}시 ${refinedMin}분`;
    const [selectedMer, setSelectedMer] = useState("오전");
    const [selectedHour, setSelectedHour] = useState(1);
    const [selectedMin, setSelectedMin] = useState("00");
    const [merRef, setMerRef] = useState(null);
    const [hourRef, setHourRef] = useState(null);
    const [minRef, setMinRef] = useState(null);
    const [sheetBarRef, setSheetBarRef] = useState(null);
    const isDefaultDateReady = useRef(false);

    const handleScrollSelection = (ref, fn) => {
        if (isDefaultDateReady.current) {
            const barRect = sheetBarRef.getBoundingClientRect();

            Array.from(ref.children).forEach(li => {
                const targetRect = li.getBoundingClientRect();
                const isInsideBar = Math.abs(barRect.top - targetRect.top) < 20;
                const isFunc = typeof fn === "function";

                if (isFunc && isInsideBar) {
                    fn(li);
                }
            });
        }
    };

    useEffect(() => {
        if (isShown) {
            $body.classList.add("blocked-scrolling");
        } else {
            $body.classList.remove("blocked-scrolling");

            isDefaultDateReady.current = false;
        }
    }, [isShown]);

    useEffect(() => {
        const isReady = isShown && merRef && hourRef && minRef;

        if (isReady && !isDefaultDateReady.current) {
            setSelectedMer(meridiem);
            setSelectedHour(hour);
            setSelectedMin(refinedMin);

            isDefaultDateReady.current = true;
        }
    }, [isShown, meridiem, hour, refinedMin, merRef, hourRef, minRef]);

    return (
        <Box className={css.timeSelection}>
            <Paragraph marginBottom={8}>시간 선택*</Paragraph>

            <Box
                position={"relative"}
                paddingTop={13}
                paddingBottom={11}
                backgroundColor={"#f7f7f7"}
                borderRadius={8}
                textAlign={"center"}
                border={"solid 1px #ededed"}
                onClick={() => isEditable && setIsShown(true)}
            >
                {timeTxt}

                <Box transform={"translate(-50%,-50%)"} position={"absolute"} top={"50%"} right={8} height={24}>
                    <DropDown />
                </Box>
            </Box>

            {isShown && (
                <Box
                    className={css.sheet}
                    position={"fixed"}
                    width={"100vw"}
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    zIndex={999}
                >
                    <Box width={"100%"} height={"100%"} opacity={0.6} backgroundColor={"#000"} />

                    <Box
                        position={"fixed"}
                        backgroundColor={"#fff"}
                        borderTopLeftRadius={24}
                        borderTopRightRadius={24}
                        paddingTop={24}
                        paddingX={20}
                        height={310}
                        left={0}
                        right={0}
                        bottom={0}
                    >
                        <FlexBox alignItems={"center"} row>
                            <StopWatch />{" "}
                            <Strong size={"big"} marginLeft={8}>
                                예약 시간 선택하기
                            </Strong>
                        </FlexBox>

                        <FlexBox position={"relative"} width={"100%"} height={110} marginTop={26} marginBottom={32}>
                            <FlexBox position={"absolute"} height={110} alignItems={"center"} zIndex={-1}>
                                <Box is={"img"} src={sheetBar} width={"100%"} height={48} ref={setSheetBarRef} />
                            </FlexBox>

                            <FlexBox flex={1} className={css.meridiem} col>
                                <Box
                                    is={"ul"}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    ref={setMerRef}
                                    className={"hidden-scrollbar"}
                                    onScroll={() =>
                                        handleScrollSelection(merRef, li => {
                                            if (selectedMer !== li.textContent) {
                                                setSelectedMer(li.textContent);
                                            }
                                        })
                                    }
                                >
                                    {fillEmptyToScroll(MERIDIEM).map((merText, idx) => {
                                        return (
                                            <Box
                                                is={"li"}
                                                key={`${merText}-${idx + 1}`}
                                                padding={merText ? "auto" : "15px"}
                                                className={selectedMer === merText ? "selected" : ""}
                                            >
                                                {merText}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </FlexBox>

                            <FlexBox flex={1} className={css.hours} col>
                                <Box
                                    is={"ul"}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    ref={setHourRef}
                                    className={"hidden-scrollbar"}
                                    onScroll={() =>
                                        handleScrollSelection(hourRef, li => {
                                            const parsed = parseInt(li.textContent, 10);

                                            if (selectedHour !== parsed) {
                                                setSelectedHour(parsed);
                                            }
                                        })
                                    }
                                >
                                    {fillEmptyToScroll(HOURS).map((no, idx) => {
                                        return (
                                            <Box
                                                is={"li"}
                                                key={`${no}-${idx + 1}`}
                                                padding={no ? "auto" : "15px"}
                                                className={selectedHour === no ? "selected" : ""}
                                            >
                                                {no ? `${padZeros(2, no)}시` : ""}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </FlexBox>

                            <FlexBox flex={1} className={css.mins} col>
                                <Box
                                    is={"ul"}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    ref={setMinRef}
                                    className={"hidden-scrollbar"}
                                    onScroll={() =>
                                        handleScrollSelection(minRef, li => {
                                            const parsed = li.textContent.replace("분", "");

                                            if (selectedMin !== parsed) {
                                                setSelectedMin(parsed);
                                            }
                                        })
                                    }
                                >
                                    {fillEmptyToScroll(MINS).map((minText, idx) => {
                                        return (
                                            <Box
                                                is={"li"}
                                                key={`${minText}-${idx + 1}`}
                                                padding={minText ? "auto" : "15px"}
                                                className={selectedMin === minText ? "selected" : ""}
                                            >
                                                {minText ? `${minText}분` : ""}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </FlexBox>
                        </FlexBox>

                        <FlexBox marginY={16} row>
                            <Button color={"outlined"} onClick={() => setIsShown(false)}>
                                취소
                            </Button>
                            <Box paddingX={4} />
                            <Button
                                color={"night"}
                                onClick={() =>
                                    onConfirm({
                                        meridiem: selectedMer,
                                        hour: selectedHour,
                                        minute: selectedMin
                                    })
                                }
                            >
                                확인
                            </Button>
                        </FlexBox>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

TimeSelection.propTypes = {
    isShown: PropTypes.bool.isRequired,
    setIsShown: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    selectedDate: PropTypes.instanceOf(Date).isRequired,
    isEditable: PropTypes.bool
};

TimeSelection.defaultProps = {
    isEditable: false
};

export default TimeSelection;
