import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import css from "./progress.css";
import FlexBox from "../FlexBox";

function Progress({ value }) {
    return (
        <FlexBox className={cx(css.progress)}>
            <div
                className={cx(css.progressBar)}
                style={{
                    flexBasis: `${value}%`
                }}
            />
        </FlexBox>
    );
}

Progress.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default Progress;
