import * as types from "../actionTypes";

const initialState = {
    tel: "1599-4572",
    topList: [],
    allList: [],
    byLetter: {},
    terms: "",
    privacy: "",
    thirdParty: "",
    errors: {
        getCarBrandList: null
    }
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.REQUEST_GET_CAR_BRAND_LIST: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    getCarBrandList: null
                }
            };
        }

        case types.FAILURE_GET_CAR_BRAND_LIST: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    getCarBrandList: payload
                }
            };
        }

        case types.SUCCESS_GET_CAR_BRAND_LIST: {
            return {
                ...state,
                topList: payload.topList,
                allList: payload.allList,
                byLetter: payload.byLetter,
                errors: {
                    ...state.errors,
                    getCarBrandList: null
                }
            };
        }

        case types.REQUEST_GET_TERMS: {
            return {
                ...state,
                terms: "",
                errors: {
                    ...state.errors,
                    getTerms: null
                }
            };
        }

        case types.FAILURE_GET_TERMS: {
            return {
                ...state,
                terms: "",
                errors: {
                    ...state.errors,
                    getTerms: payload
                }
            };
        }

        case types.SUCCESS_GET_TERMS: {
            return {
                ...state,
                terms: payload,
                errors: {
                    ...state.errors,
                    getTerms: null
                }
            };
        }

        case types.REQUEST_GET_PRIVACY: {
            return {
                ...state,
                privacy: "",
                errors: {
                    ...state.errors,
                    getPrivacy: null
                }
            };
        }

        case types.FAILURE_GET_PRIVACY: {
            return {
                ...state,
                privacy: "",
                errors: {
                    ...state.errors,
                    getPrivacy: payload
                }
            };
        }

        case types.SUCCESS_GET_PRIVACY: {
            return {
                ...state,
                privacy: payload,
                errors: {
                    ...state.errors,
                    getPrivacy: null
                }
            };
        }

        case types.REQUEST_GET_3RD_PARTY: {
            return {
                ...state,
                thirdParty: "",
                errors: {
                    ...state.errors,
                    get3rdParty: null
                }
            };
        }

        case types.FAILURE_GET_3RD_PARTY: {
            return {
                ...state,
                thirdParty: "",
                errors: {
                    ...state.errors,
                    get3rdParty: payload
                }
            };
        }

        case types.SUCCESS_GET_3RD_PARTY: {
            return {
                ...state,
                thirdParty: payload,
                errors: {
                    ...state.errors,
                    get3rdParty: null
                }
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
