import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { identity } from "ramda";
import { remove } from "~/packages/utils";
import css from "./upload-image.css";
import Wrap from "../Wrap";
import Text from "../Text";
import FlexBox from "../FlexBox";
import Image from "../Image";
import { Camera, Plus, Trash } from "../../svg";

function UploadImage({ placeholder, onChange, onUpoadLog }) {
    const fileRef = useRef(null);
    const [imagesAsBlob, setImagesAsBlob] = useState([]);
    const [imagesAsObj, setImagesAsObj] = useState([]);

    const handleChangeInput = async evt => {
        const targetFiles = [...evt.target.files];

        if (targetFiles.length > 0) {
            const uploadedImages = targetFiles.map(URL.createObjectURL);

            setImagesAsObj([...imagesAsObj, ...targetFiles]);
            setImagesAsBlob([...imagesAsBlob, ...uploadedImages]);
        }
    };

    const handleClickTrash = idx => {
        setImagesAsObj(remove(idx, imagesAsObj));
        setImagesAsBlob(remove(idx, imagesAsBlob));
    };

    useEffect(() => {
        onChange(imagesAsObj);
    }, [imagesAsObj]);

    return (
        <>
            <div className={cx(css.uploadImage)}>
                {imagesAsBlob.length ? (
                    <ol>
                        {imagesAsBlob.map((image, idx) => {
                            return (
                                <li tabIndex={idx} key={image}>
                                    <Text type={"inline"} size={"small"} className={css.uploadImageCounter}>
                                        {`${idx + 1}/${imagesAsBlob.length}`}
                                    </Text>
                                    <Trash
                                        className={cx(css.uploadImageTrashIcon)}
                                        onClick={evt => handleClickTrash(idx, evt)}
                                    />
                                    <figure>
                                        <Image src={image} alt={""} className={cx(css.uploadImageFile)} />
                                    </figure>
                                </li>
                            );
                        })}
                    </ol>
                ) : (
                    <Wrap paddingTop={"48px"}>
                        <FlexBox col middle>
                            {placeholder && (
                                <>
                                    <Camera />
                                    <Text size={"small"} color={"gray700"} list={placeholder} />
                                </>
                            )}
                        </FlexBox>
                    </Wrap>
                )}

                <Wrap
                    position={"absolute"}
                    right={"20px"}
                    bottom={"20px"}
                    display={"inline-block"}
                    padding={"11px 10px 11px 4px"}
                    borderRadius={"19px"}
                    border={"solid 1px #000"}
                    backgroundColor={"#fff"}
                    onClick={() => {
                        fileRef.current.click();
                    }}
                >
                    <input
                        type={"file"}
                        ref={fileRef}
                        id={"req-repair-pic"}
                        name={"req-repair-pic"}
                        accept={"image/png, image/jpeg"}
                        multiple
                        className={"is-hidden"}
                        onClick={onUpoadLog}
                        onChange={handleChangeInput}
                    />
                    <FlexBox row middle>
                        <Plus />
                        <Text size={"small"}>사진 추가하기</Text>
                    </FlexBox>
                </Wrap>
            </div>
        </>
    );
}

UploadImage.propTypes = {
    placeholder: PropTypes.node,
    onChange: PropTypes.func,
    onUpoadLog: PropTypes.func
};

UploadImage.defaultProps = {
    placeholder: "",
    onChange: identity,
    onUpoadLog: identity
};

export default UploadImage;
