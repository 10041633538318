import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { identity } from "ramda";
import css from "./more.css";
import Text from "../Text";
import { RightArrow } from "../../svg";

function More({ children, className, color, arrow, onClick }) {
    return (
        <Text size={"small"} color={color} type={"inline"} className={cx(css.more, className)} onClick={onClick}>
            {children} {arrow && <RightArrow />}
        </Text>
    );
}

More.propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.string,
    arrow: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func
};

More.defaultProps = {
    className: "",
    color: "gray700",
    arrow: false,
    onClick: identity
};

export default More;
