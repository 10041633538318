import React from "react";
import PropTypes from "prop-types";
import Box from "ui-box";

function Partial({ children, ...restProps }) {
    return (
        <Box paddingX={20} paddingY={24} {...restProps}>
            {children}
        </Box>
    );
}

Partial.propTypes = {
    children: PropTypes.node.isRequired
};

export default Partial;
