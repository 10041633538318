import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Text, Progress, Wrap, FlexBox } from "~/packages/ui/mobile";
import { stringToFloat } from "~/utils";
import css from "./satisfaction-graph.css";

function SatisfactionGraph({ ratio, symbol, data }) {
    return (
        <div className={cx(css.satisfactionGraph)}>
            <FlexBox row middle>
                <FlexBox col>
                    <Text
                        style={{
                            color: "#000"
                        }}
                        size={"30px"}
                        weight={"bold"}
                    >
                        {ratio}
                        <Text type={"inline"} size={"normal"}>
                            {symbol}
                        </Text>
                    </Text>

                    <Text
                        size={"smaller"}
                        align={"center"}
                        style={{
                            backgroundColor: "#fff",
                            paddingTop: "3px",
                            paddingBottom: "3px",
                            paddingLeft: "8px",
                            paddingRight: "8px",
                            margin: 0,
                            borderRadius: "12px",
                            border: "1px solid #000"
                        }}
                    >
                        수리 만족도
                    </Text>
                </FlexBox>

                <Wrap
                    flexBasis={"1px"}
                    borderRight={"1px solid #dadada"}
                    margin={"0 16px 0 20px"}
                    alignSelf={"stretch"}
                >
                    &nbsp;
                </Wrap>

                <Wrap flexGrow={"1"}>
                    <table
                        style={{
                            width: "100%"
                        }}
                    >
                        <tbody>
                            {data.map(({ title, progress }) => {
                                return (
                                    <tr key={title}>
                                        <th style={{ width: "70px", textAlign: "left" }}>
                                            <Text size={"smaller"} color={"gray700"}>
                                                {title}
                                            </Text>
                                        </th>
                                        <td style={{ width: "30px" }}>
                                            <Text weight={"bold"} size={"smaller"} color={"gray"}>
                                                {stringToFloat((progress.value / 100) * progress.max, 1)}
                                            </Text>
                                        </td>
                                        <td>
                                            <Progress {...progress} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Wrap>
            </FlexBox>
        </div>
    );
}

SatisfactionGraph.propTypes = {
    ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            progress: PropTypes.shape({
                max: PropTypes.number,
                value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            }).isRequired
        })
    ).isRequired,
    symbol: PropTypes.string
};

SatisfactionGraph.defaultProps = {
    symbol: "%"
};

export default SatisfactionGraph;
