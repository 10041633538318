import React from "react";
import PropTypes from "prop-types";
import { compose, split, identity } from "ramda";
import Box from "ui-box";
import { getDateString, padZeros, isSameDate, getDatesInRange } from "~/packages/utils";
import Text from "../Text";
import Paragraph from "../Paragraph";
import FlexBox from "../FlexBox";
import Strong from "../Strong";

// TODO render items separately
function DateSelection({ initialDate, selectedDate, dates, onClick, isEditable }) {
    const [year, month, date] = compose(split(" "), getDateString)(selectedDate);
    const rangedDates = getDatesInRange(dates, initialDate);
    const selectedDateText = `${year}${padZeros(2, Number(month))}.${padZeros(2, Number(date))}`;

    return (
        <Box>
            <FlexBox spaceBetween row>
                <Paragraph marginBottom={8}>날짜 선택*</Paragraph>
                <Text color={"gray"}>{selectedDateText}</Text>
            </FlexBox>

            <FlexBox className={"hidden-scrollbar"} flex={1} overflow={"auto"} marginTop={8} row>
                {rangedDates.map(item => {
                    const isToday = isSameDate(new Date(), item.date);
                    const isBookingDay = isSameDate(selectedDate, item.date);
                    const handleClick = isEditable ? onClick(item) : () => identity(item);

                    return (
                        <FlexBox
                            key={item.id}
                            alignItems={"center"}
                            marginRight={15}
                            paddingY={11}
                            flexBasis={35}
                            flexGrow={0}
                            flexShrink={0}
                            borderRadius={17.5}
                            color={isBookingDay ? "#fff" : "#000"}
                            backgroundColor={isBookingDay ? "#000" : "#fff"}
                            onClick={handleClick}
                            col
                        >
                            <Text size={"12px"}>{item.day}</Text>
                            <Strong marginTop={4}>{padZeros(2, item.date.getDate())}</Strong>
                            {isToday && <Text size={"10px"}>오늘</Text>}
                        </FlexBox>
                    );
                })}
            </FlexBox>
        </Box>
    );
}

DateSelection.propTypes = {
    initialDate: PropTypes.instanceOf(Date).isRequired,
    selectedDate: PropTypes.instanceOf(Date).isRequired,
    dates: PropTypes.number,
    onClick: PropTypes.func,
    isEditable: PropTypes.bool
};

DateSelection.defaultProps = {
    dates: 62,
    onClick: identity,
    isEditable: false
};

export default DateSelection;
