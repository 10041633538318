import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import css from "./card.css";

function Card({ children, className }) {
    return <div className={cx(css.card, className)}>{children}</div>;
}

Card.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

Card.defaultProps = {
    className: ""
};

export default Card;
