import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Text from "../Text";

function TextEllipsis({ maxLength, text }) {
    const [more, setMore] = useState(false);
    const [textList, setTextList] = useState({
        text: text.split(/\n/),
        excerpt: []
    });

    useEffect(() => {
        if (text.length > maxLength) {
            const splittedText = text.substr(0, maxLength).split(/\n/);
            const [last, ...restReversedTextList] = [...splittedText].reverse();

            setMore(true);
            setTextList({
                ...textList,
                excerpt: [`${last} ...`, restReversedTextList].reverse()
            });
        }
    }, [text]);

    return (
        <>
            {<Text list={more ? textList.excerpt : textList.text} />}
            {more && (
                <Text
                    color={"blue"}
                    onClick={() => {
                        setMore(false);
                    }}
                >
                    더보기
                </Text>
            )}
        </>
    );
}

TextEllipsis.propTypes = {
    text: PropTypes.string.isRequired,
    maxLength: PropTypes.number
};

TextEllipsis.defaultProps = {
    maxLength: 100
};

export default TextEllipsis;
