import * as types from "../actionTypes";

const initialState = {
    userkey: null,
    promocode: null,
    accReqNoEnc: null,
    accReqNo: null,
    vinEnc: null,
    reqid: null,
    shopid: null,
    jumbotron: {},
    errors: {
        saveUserkey: null,
        getJumbotronData: null
    }
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.SAVE_PARSED_QUERIED: {
            return {
                ...state,
                ...payload
            };
        }

        case types.GENERATE_USERKEY: {
            return {
                ...state,
                userkey: null,
                errors: {
                    ...state.errors,
                    saveUserkey: null
                }
            };
        }

        case types.USERKEY_ERROR: {
            return {
                ...state,
                userkey: null,
                errors: {
                    ...state.errors,
                    saveUserkey: payload
                }
            };
        }

        case types.SAVE_USERKEY: {
            return {
                ...state,
                userkey: payload,
                errors: {
                    ...state.errors,
                    saveUserkey: null
                }
            };
        }

        case types.REQUEST_JUMBOTRON_DATA: {
            return {
                ...state,
                jumbotron: {},
                errors: {
                    ...state.errors,
                    getJumbotronData: null
                }
            };
        }

        case types.FAILURE_JUMBOTRON_DATA: {
            return {
                ...state,
                jumbotron: {},
                errors: {
                    ...state.errors,
                    getJumbotronData: payload
                }
            };
        }

        case types.SUCCESS_JUMBOTRON_DATA: {
            return {
                ...state,
                jumbotron: payload,
                errors: {
                    ...state.errors,
                    getJumbotronData: null
                }
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
