import { connect } from "react-redux";
import { ViewReceived } from "~/pages";
import { parseQueries, getEstByShop, getSentEstMetadata } from "~/store/actions";

function mapStateToProps({ assets: { tel }, received: { reqid, accReqNo, insuName, estList, sentEst } }) {
    return {
        reqid,
        accReqNo,
        insuName,
        estList,
        tel,
        promotioncode: sentEst.promotioncode
    };
}

function mapDispatchToProps(dispatch) {
    return {
        parseQueries: (query = window.location.search) => dispatch(parseQueries(query)),
        getSentEstMetadata: () => dispatch(getSentEstMetadata()),
        getEstByShop: () => dispatch(getEstByShop())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewReceived);
