import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { identity } from "ramda";
import { useIntersect } from "~/utils/hooks";
import css from "./image-viewer.css";
import Wrap from "../Wrap";
import Text from "../Text";
import FlexBox from "../FlexBox";
import Image from "../Image";
import { Close } from "../../svg";

function ImageViewer({ files, onClose }) {
    const [currentIdx, setCurrentIdx] = useState(1);
    const [, setViewerRootRef] = useIntersect(
        entry => {
            if (entry.isIntersecting) {
                setCurrentIdx(entry.target.getAttribute("data-idx"));
            }
        },
        {
            threshold: 1.0
        },
        ({ ref, observer }) => {
            const lis = ref.querySelectorAll("li");

            lis.forEach(li => {
                observer.observe(li);
            });
        }
    );

    return (
        <>
            <Wrap position={"absolute"} top={"24px"} left={"20px"} right={"20px"} zIndex={"999999"}>
                <FlexBox row>
                    <FlexBox
                        center
                        middle
                        style={{
                            flexBasis: "60px"
                        }}
                    />

                    <FlexBox
                        center
                        middle
                        style={{
                            flex: "1"
                        }}
                    >
                        <Text
                            size={"smaller"}
                            align={"center"}
                            style={{
                                backgroundColor: "#fff",
                                color: "#000",
                                width: "48px",
                                padding: "3px 8px",
                                borderRadius: "12px",
                                border: "1px solid #000"
                            }}
                        >
                            {`${currentIdx}/${files.length}`}
                        </Text>
                    </FlexBox>

                    <FlexBox
                        center
                        middle
                        style={{
                            flexBasis: "60px"
                        }}
                    >
                        <Close onClick={onClose} />
                    </FlexBox>
                </FlexBox>
            </Wrap>

            <FlexBox middle className={cx(css.imageViewer)}>
                <ol ref={setViewerRootRef}>
                    {files.map((file, idx) => {
                        return (
                            <li key={file.url} data-idx={idx + 1}>
                                <Image src={file.url} alt={file.desc} />
                                <Text style={{ margin: "24px 0 0 20px" }}>{file.desc}</Text>
                            </li>
                        );
                    })}
                </ol>
            </FlexBox>
        </>
    );
}

ImageViewer.propTypes = {
    files: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            desc: PropTypes.string.isRequired
        })
    ).isRequired,
    onClose: PropTypes.func
};

ImageViewer.defaultProps = {
    onClose: identity
};

export default ImageViewer;
