import { useEffect, useState } from "react";
import { F, identity } from "ramda";
import { isEvent } from "~/packages/utils";

function useForm({ initialValues = [], validate = F, onChange = identity, onSubmit = identity }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isValidated, setIsValidted] = useState(false);
    const [formState, setFormState] = useState(initialValues);
    const getFieldProps = fieldName => {
        return {
            name: fieldName,
            value: formState[fieldName],
            onChange: evt => {
                setFormState({
                    ...formState,
                    [fieldName]: isEvent(evt) ? evt.target.value : evt
                });
            }
        };
    };

    useEffect(() => {
        setIsValidted(validate(formState));
        onChange(formState);
    }, [formState]);

    return {
        handleSubmit: () => {
            try {
                if (!isValidated) {
                    throw new Error("invalid");
                }

                if (!isSubmitting) {
                    setIsSubmitting(true);
                    onSubmit(formState);
                }
            } catch (err) {
                // TODO handle error
            }
        },
        isSubmitting,
        formState,
        setFormState,
        getFieldProps,
        isValidated
    };
}

export default useForm;
