import { REQUEST_GET_SHOP, SUCCESS_GET_SHOP, FAILURE_GET_SHOP } from "../actionTypes";

export function getShop() {
    return async (dispatch, getState, api) => {
        const {
            bootstrap: { shopid }
        } = getState();

        dispatch({
            type: REQUEST_GET_SHOP
        });

        try {
            const shop = await api.getShop(shopid);

            dispatch({
                type: SUCCESS_GET_SHOP,
                payload: shop
            });
        } catch (err) {
            dispatch({
                type: FAILURE_GET_SHOP,
                payload: err
            });
        }
    };
}
