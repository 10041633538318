import React from "react";
import PropTypes from "prop-types";
import { identity } from "ramda";
import { Section, Text, Wrap, FlexBox, TextEllipsis } from "~/packages/ui/mobile";
import { Copy, Location, Clock, Call, Script } from "~/packages/ui/svg";
import { phoneCall } from "~/services/device";
import { copyToClipboard } from "~/utils";

function ShopInfo({ maxLength, shopDesc, shopAddress, shopNo, shopBusiness, getWorkingDays }) {
    return (
        <>
            <Section>
                <Section.Content>
                    <Wrap padding={"24px 20px"}>
                        <Wrap marginBottom={"16px"}>
                            <FlexBox row>
                                <Wrap width={"20px"} marginRight={"8px"}>
                                    <Location />
                                </Wrap>

                                <FlexBox row middle>
                                    <Text
                                        style={{
                                            marginRight: "8px"
                                        }}
                                    >
                                        {shopAddress}
                                    </Text>
                                    <Copy
                                        onClick={() => {
                                            copyToClipboard(shopAddress);
                                        }}
                                    />
                                    <Text
                                        color={"blue"}
                                        onClick={() => {
                                            copyToClipboard(shopAddress);
                                        }}
                                    >
                                        복사
                                    </Text>
                                </FlexBox>
                            </FlexBox>
                        </Wrap>

                        <Wrap marginBottom={"16px"}>
                            <FlexBox row>
                                <Wrap width={"20px"} marginRight={"8px"}>
                                    <Clock />
                                </Wrap>
                                <FlexBox col>
                                    <Text list={getWorkingDays(shopBusiness)} />
                                </FlexBox>
                            </FlexBox>
                        </Wrap>

                        <Wrap marginBottom={"16px"}>
                            <FlexBox row>
                                <Wrap width={"20px"} marginRight={"8px"}>
                                    <Call />
                                </Wrap>
                                <Text color={"blue"} onClick={() => phoneCall(shopNo.mobile, shopNo.tel)}>
                                    전화문의하기 &gt;
                                </Text>
                            </FlexBox>
                        </Wrap>

                        <FlexBox row>
                            <Wrap width={"20px"} marginRight={"8px"}>
                                <Script />
                            </Wrap>
                            <FlexBox col>
                                <TextEllipsis maxLength={maxLength} text={shopDesc} />
                            </FlexBox>
                        </FlexBox>
                    </Wrap>
                </Section.Content>
            </Section>
        </>
    );
}

ShopInfo.propTypes = {
    shopDesc: PropTypes.string.isRequired,
    shopAddress: PropTypes.string.isRequired,
    shopNo: PropTypes.shape({
        mobile: PropTypes.string,
        tel: PropTypes.string
    }).isRequired,
    shopBusiness: PropTypes.shape({
        weekdays: PropTypes.string.isRequired,
        weekends: PropTypes.string,
        holidays: PropTypes.string
    }).isRequired,
    getWorkingDays: PropTypes.func,
    maxLength: PropTypes.number
};

ShopInfo.defaultProps = {
    getWorkingDays: identity,
    maxLength: 100
};

export default ShopInfo;
