import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Text from "../Text";
import css from "./carousel.css";
import Image from "../Image";
import Wrap from "../Wrap";
import FlexBox from "../FlexBox";

function Carousel({ list, caption }) {
    const { logo, name, plateNumber } = caption || {};

    return (
        <section className={cx(css.carousel)}>
            <ol>
                {list.map((image, idx) => {
                    return (
                        <li tabIndex={idx} key={image}>
                            <Text type={"inline"} size={"small"} className={css.carouselIndicator}>
                                {`${idx + 1}/${list.length}`}
                            </Text>

                            <figure>
                                <Image src={image} alt={name} className={css.carouselImage} />
                            </figure>
                        </li>
                    );
                })}
            </ol>

            {caption && (
                <FlexBox row middle className={css.carouselCaption}>
                    <Wrap marginRight={"16px"}>{logo}</Wrap>

                    <FlexBox col>
                        <Text color={"gray700"} weight={"bold"}>
                            {name}
                        </Text>

                        <Text color={"gray700"} size={"small"}>
                            {plateNumber}
                        </Text>
                    </FlexBox>
                </FlexBox>
            )}
        </section>
    );
}

Carousel.propTypes = {
    list: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    caption: PropTypes.shape({
        logo: PropTypes.node,
        name: PropTypes.string,
        plateNumber: PropTypes.string
    })
};

Carousel.defaultProps = {
    caption: null
};

export default Carousel;
