function bindParams(url, params = {}) {
    return url
        .split("/")
        .map(token => {
            if (token.startsWith(":")) {
                const key = token.substr(1);

                return `${params[key]}`;
            }

            return token;
        })
        .join("/");
}

export default bindParams;
