import createRequest from "~/packages/request";
import { clean, bindParams } from "~/packages/utils";

const { MODE, API_ENDPOINT, API_VERSION } = process.env;
const IS_DEV = MODE === "development";

const request = createRequest({
    options: {
        baseURL: clean([!IS_DEV && API_ENDPOINT, "/", API_VERSION]).join("")
    },
    onSuccess: ({ data }) => data
});

/**
 * Get jumbotron data (Reviews Carousel)
 * @return {Promise}
 */
export function getJumbotronData() {
    return request.get("/mReqInit");
}

/**
 * Update estimate
 * @return {Promise}
 */
export function updateEstimate({ estid, data }) {
    return request.put(
        bindParams("/estimation/:estid", {
            estid
        }),
        data
    );
}

/**
 * Cancel booking
 * @param  {String}  estId
 * @param  {Object}  data
 * @return {Promise}
 */
export function cancelBooking(...args) {
    return updateEstimate(...args);
}

/**
 * Update booking
 * @param  {String}  estId
 * @param  {Object}  data
 * @return {Promise}
 */
export function updateBooking(...args) {
    return updateEstimate(...args);
}

/**
 * Request booking
 * @param  {String}  estId
 * @param  {Object}  data
 * @return {Promise}
 */
export function requestBooking(...args) {
    return updateEstimate(...args);
}
