import { createContext, useContext } from "react";

const COLOR_SET = {
    blue: "#0068ff",
    black: "#000",
    white: "#fff",
    gray: "#222",
    gray700: "#999",
    gray600: "#cecece",
    gray500: "#dadada",
    gray400: "#eaeaea",
    gray300: "#ededed",
    gray200: "#f4f4f4",
    gray100: "#f7f7f7"
};
const BORDER_RADIUS = "16px";
const BOX_SHADOW = "0 2px 8px 0 rgba(0, 0, 0, 0.05)";

const ALIGN = {
    right: "right",
    left: "left",
    center: "center"
};

export const theme = {
    size: {
        biggest: "22px",
        bigger: "20px",
        big: "18px",
        normal: "16px",
        small: "14px",
        smaller: "12px"
    },

    weight: {
        bold: "600",
        normal: "normal"
    },

    color: COLOR_SET,

    borderColor: COLOR_SET.gray300,
    borderRadius: BORDER_RADIUS,
    boxShadow: BOX_SHADOW,

    height: {
        navi: 60
    },

    align: ALIGN
};

export const ThemeContext = createContext();

export default function useTheme() {
    return useContext(ThemeContext);
}
