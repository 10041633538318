import getWeekday from "./getWeekday";

function getDatesInRange(size, date, options = { weekday: "short" }) {
    const startDate = new Date(date);

    return [...Array(size).keys()].map(key => {
        const cloneDate = new Date(startDate);

        const theDayAfter = cloneDate.getDate() + key;
        const thatDate = cloneDate.setDate(theDayAfter);

        return {
            id: thatDate,
            date: new Date(thatDate),
            day: getWeekday(thatDate, options),
            month: new Date().getMonth() + 1
        };
    });
}

export default getDatesInRange;
