import { createContext, useContext } from "react";
import amplitude_ from "amplitude-js";

class Amplitude {
    constructor(name) {
        this.instance = null;
        this.projectName = name;
    }

    init(apiKey, userId) {
        if (apiKey) {
            this.instance = amplitude_.getInstance(this.projectName);
            this.instance.init(apiKey, userId);
        }
    }

    logEvent(...args) {
        if (this.instance) {
            this.instance.logEvent(...args);
        }
    }
}

export const amplitude = new Amplitude("mreq");

export const AmplitudeContext = createContext();

export default function useAmplitude() {
    return useContext(AmplitudeContext);
}
