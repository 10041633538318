import axios from "axios";
import interceptors from "./interceptors";

function createRequest(config = {}) {
    const { options = {} } = config;
    const { timeout = 30000, baseURL, ...defOptions } = options;
    const configureIntercetpors = interceptors(config);
    const instance = axios.create({
        withCredentials: true,
        timeout,
        baseURL,
        ...defOptions
    });

    return configureIntercetpors(instance);
}

export default createRequest;
