import React from "react";
import PropTypes from "prop-types";
import Box from "ui-box";
import { identity } from "ramda";
import useTheme from "../theme";

function Text({ tag, type, list, className, children, size, color, weight, align, style, onClick }) {
    const theme = useTheme();
    const tagName = type === "inline" ? "span" : tag;
    const isSizeCustomized = size && !theme.size[size];

    if (list) {
        return list.map((txt, idx) => {
            const uniqueKey = `${txt}-${idx}`;

            return (
                <Box
                    key={uniqueKey}
                    is={tagName}
                    className={className}
                    fontWeight={theme.weight[weight]}
                    fontSize={isSizeCustomized ? size : theme.size[size]}
                    color={theme.color[color]}
                    textAlign={theme.align[align]}
                    {...style}
                >
                    {txt}
                </Box>
            );
        });
    }

    return (
        <Box
            is={tagName}
            className={className}
            fontWeight={theme.weight[weight]}
            fontSize={isSizeCustomized ? size : theme.size[size]}
            color={theme.color[color]}
            textAlign={theme.align[align]}
            onClick={onClick}
            {...style}
        >
            {children}
        </Box>
    );
}

Text.propTypes = {
    tag: PropTypes.string,
    children: PropTypes.node,
    list: PropTypes.arrayOf(PropTypes.node),
    className: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.string,
    weight: PropTypes.string,
    align: PropTypes.string,
    style: PropTypes.shape({}),
    onClick: PropTypes.func
};

Text.defaultProps = {
    tag: "p",
    list: null,
    children: "",
    className: "",
    size: "normal",
    type: "",
    color: "grey",
    weight: "normal",
    align: "",
    style: {},
    onClick: identity
};

export default Text;
