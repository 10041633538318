import React from "react";
import Box from "ui-box";
import useTheme from "../theme";

function Gap() {
    const theme = useTheme();

    return (
        <Box
            height={10}
            backgroundColor={theme.color.gray100}
            borderTopWidth={1}
            borderBottomWidth={1}
            borderTopStyle={"solid"}
            borderBottomStyle={"solid"}
            borderTopColor={theme.color.gray300}
            borderBottomColor={theme.color.gray300}
        />
    );
}

export default Gap;
