import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { identity } from "ramda";
import css from "./auto-suggest.css";
import Text from "../Text";
import Wrap from "../Wrap";
import FlexBox from "../FlexBox";
import { Search } from "../../svg";

function TextField({
    type,
    name,
    value,
    placeholder,
    children,
    readOnly,
    limit,
    onChange,
    onSelect,
    onUpdate,
    onBlur,
    isRequired
}) {
    const [suggestion, setSuggestion] = useState([]);

    return (
        <div className={cx(css.autoSuggest)}>
            {children && (
                <Text size={"small"} color={"gray"} className={css.autoSuggestLabel}>
                    {children}
                    {isRequired && "*"}
                </Text>
            )}
            <input
                type={type}
                name={name}
                value={value}
                placeholder={placeholder}
                className={cx(css.autoSuggestInput, {
                    [css.autoSuggestInputTiggered]: !!suggestion.length
                })}
                readOnly={readOnly}
                onChange={onChange}
                onBlur={() => {
                    onBlur?.();
                    setSuggestion([]);
                }}
                onKeyUp={async () => {
                    const list = await onUpdate(value, limit);

                    if (list) {
                        setSuggestion(list.slice(0, limit));
                    }
                }}
            />
            {!!suggestion.length && (
                <div className={cx(css.autoSuggestBox)}>
                    <ul className={cx(css.autoSuggestBoxInner)}>
                        {suggestion.map((suggest, idx) => {
                            const uniqueKey = typeof suggest === "object" ? suggest.address_id : idx;

                            return (
                                <li
                                    key={uniqueKey}
                                    onMouseDown={() => {
                                        onChange(suggest.text);
                                        onSelect(suggest);
                                        setSuggestion([]);
                                    }}
                                    role={"presentation"}
                                >
                                    <FlexBox row middle>
                                        <Search />
                                        <Wrap width={"4px"} />
                                        <Text color={"black"}>{suggest.text}</Text>
                                    </FlexBox>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
}

TextField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    limit: PropTypes.number,
    placeholder: PropTypes.string,
    children: PropTypes.node,
    readOnly: PropTypes.bool,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    onUpdate: PropTypes.func
};

TextField.defaultProps = {
    type: "text",
    name: "",
    value: "",
    limit: 5,
    placeholder: "",
    children: "",
    readOnly: false,
    isRequired: false,
    onChange: identity,
    onSelect: identity,
    onUpdate: identity
};

export default TextField;
