import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { identity } from "ramda";
import Text from "../Text";
import css from "./textbox.css";

function TextBox({ placeholder, children, value, onChange, readOnly, isRequired }) {
    return (
        <div className={cx(css.textbox)}>
            {children && (
                <Text size={"small"} color={"gray"} className={css.textboxLabel}>
                    {children}
                    {isRequired && "*"}
                </Text>
            )}
            <textarea
                placeholder={Array.isArray(placeholder) ? placeholder.join("\n") : placeholder}
                className={cx(css.textboxTextarea)}
                value={value}
                onChange={onChange}
                readOnly={readOnly}
            />
        </div>
    );
}

TextBox.propTypes = {
    placeholder: PropTypes.node,
    children: PropTypes.node,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func
};

TextBox.defaultProps = {
    placeholder: "",
    children: "",
    value: "",
    readOnly: false,
    isRequired: false,
    onChange: identity
};

export default TextBox;
