import React, { Children } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import css from "./section.css";
import Title from "../Title";
import Card from "../Card";

function Section({ title, moreArrow, children }) {
    let _shouldDisplayBorder = false;

    Children.forEach(children, ({ props }) => {
        // eslint-disable-next-line
        _shouldDisplayBorder = props.type !== "card";
    });

    return (
        <section
            className={cx(css.section, {
                [css.sectionBorder]: _shouldDisplayBorder
            })}
        >
            {title && (
                <Title tag={"strong"} moreArrow={moreArrow} sectionTitle>
                    {title}
                </Title>
            )}
            {children}
        </section>
    );
}

Section.propTypes = {
    title: PropTypes.string,
    moreArrow: PropTypes.node,
    children: PropTypes.node
};

Section.defaultProps = {
    title: "",
    moreArrow: "",
    children: ""
};

function SectionContent({ type, children, transparent }) {
    if (type === "card") {
        return <Card className={cx(css.sectionContent)}>{children}</Card>;
    }

    return (
        <div
            className={cx(css.sectionContent, {
                "is-transparent": transparent
            })}
        >
            {children}
        </div>
    );
}

SectionContent.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.string,
    transparent: PropTypes.bool
};

SectionContent.defaultProps = {
    type: "",
    transparent: false
};

Section.Content = SectionContent;
export default Section;
