import { REQUEST_GET_SHOP, FAILURE_GET_SHOP, SUCCESS_GET_SHOP } from "../actionTypes";

// TODO use camelCase
const initialState = {
    shopid: "",
    shopname: "",
    shopdesc: "",
    address: "",
    area: {
        depth1: "",
        depth2: ""
    },
    avg_satisfaction: "",
    avg_quality: "",
    avg_kindness: "",
    avg_est_accuracy: "",
    reviewcnt: "",
    aftergalcnt: "",
    callmentnumber: "",
    telnum: "",
    business_hour_weekdays: "",
    business_hour_weekends: "",
    holidays: "",
    isLoading: false,
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_GET_SHOP: {
            return {
                ...state,
                isLoading: true,
                error: null
            };
        }

        case FAILURE_GET_SHOP: {
            return {
                ...state,
                isLoading: false,
                error: payload
            };
        }

        case SUCCESS_GET_SHOP: {
            return {
                ...state,
                ...payload,
                isLoading: false,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
