import { connect } from "react-redux";
import { Booking } from "~/pages";
import {
    parseQueries,
    get3rdParty,
    getShopEst,
    updateBookingInfoField,
    cancelBooking,
    updateBooking,
    requestBooking
} from "~/store/actions";

function mapStateToProps({
    received: {
        est: { shop, statuscode = "200", estimationReq: { reqid, regdttm } = {} }
    },
    booking: {
        form: { bookdttm }
    },
    assets: { thirdParty }
}) {
    const statusCode = Number(statuscode);
    const isEditable = statusCode < 550;

    return {
        bookdttm: bookdttm === "0000-00-00 00:00:00" ? "" : bookdttm,
        // bookedDate: bookedDate === '0000-00-00 00:00:00' ? '' : bookedDate,
        bookInitDate: regdttm,
        reqid,
        shop,
        thirdParty,
        statusCode,
        isEditable
    };
}

export default connect(mapStateToProps, {
    get3rdParty,
    getShopEst,
    parseQueries,
    updateBookingInfoField,
    cancelBooking,
    updateBooking,
    requestBooking
})(Booking);
