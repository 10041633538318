import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { identity } from "ramda";
import Box from "ui-box";
import { withErrorBoundary } from "@sentry/react";
import { INSURANCE_TEXT } from "__presets";
import { applyCurrency } from "~/packages/utils";
import {
    Button,
    Card,
    Divider,
    FlexBox,
    Modal,
    NotYet,
    Paragraph,
    Screen,
    Strong,
    TableView,
    Text,
    Title
} from "~/packages/ui/mobile";
import { phoneCall } from "~/services/device";
import { stringToFloat } from "~/utils";
import { APP, BOOKING, VIEW_SENT_EST, VIEW_SHOP, VIEW_SHOP_EST } from "../../routes";

const BTN_BOOKING = {
    300: "예약 요청하기",
    400: "예약 요청하기",
    450: "예약 변경/취소",
    500: "예약 변경/취소",
    550: "예약내역",
    600: "예약내역"
};

function ViewReceived({
    setNavToolbar,
    parseQueries,
    getSentEstMetadata,
    getEstByShop,
    reqid,
    accReqNo,
    insuName,
    estList,
    tel,
    promotioncode
}) {
    const history = useHistory();
    const [isShownBookedModal, setIsShownBookedModal] = useState(false);
    const [selectedBookedData, setSelectedBookedData] = useState({
        shop: {},
        pay_statement: {},
        price: []
    });
    const [receiveEstCnt, setReceiveEstCnt] = useState(0);
    const [isShownPopupModal, setIsShownPopupModal] = useState(false);
    const [isReservationSuccessCode, setIsReservationSuccessCode] = useState(false);

    useEffect(() => {
        const init = async () => {
            parseQueries();
            await getSentEstMetadata();
            await getEstByShop();
        };

        init();
    }, [parseQueries, getSentEstMetadata, getEstByShop]);

    useEffect(() => {
        const excluded100 = estList.filter(item => Number(item.statuscode) !== 100);
        setReceiveEstCnt(excluded100.length);

        // 예약확정(500) 이상의 상태 코드가 한 개라도 존재한다면
        setIsReservationSuccessCode(estList.some(est => Number(est.statuscode) >= 500));
    }, [estList]);

    useEffect(() => {
        setNavToolbar({
            title: "받은 견적서",
            left: "back",
            right: () => (
                <>
                    <Button
                        onClick={() => {
                            if (isReservationSuccessCode) {
                                setIsShownPopupModal(true);
                                return;
                            }

                            history.push(`${APP}?promocode=${promotioncode}&accReqNo=${accReqNo}&noredirect`);
                        }}
                        color={"outlined"}
                        style={{
                            padding: "8px",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            borderRadius: "8px"
                        }}
                    >
                        <Text
                            type={"span"}
                            size={"smaller"}
                            weight={"bold"}
                            color={"black"}
                            style={{
                                lineHeight: "14px"
                            }}
                        >
                            신규견적 <br />
                            요청하기
                        </Text>
                    </Button>

                    <Modal
                        isShown={isShownPopupModal}
                        content={
                            <FlexBox marginTop={8} marginBottom={16} width={"100%"}>
                                <Strong size={"normal"}>
                                    현재 예약 확정이 된 수리 견적을 취소 후 다시 요청할 수 있습니다.
                                </Strong>
                            </FlexBox>
                        }
                        buttons={
                            <Box marginTop={16} flex={1}>
                                <Button color={"night"} onClick={() => setIsShownPopupModal(false)}>
                                    확인
                                </Button>
                            </Box>
                        }
                    />
                </>
            )
        });
    }, [setNavToolbar, promotioncode, accReqNo, estList, isShownPopupModal, isReservationSuccessCode, history]);

    return (
        <Screen hasNavi>
            <TableView
                title={"내 신청 정보"}
                more={
                    <Text
                        type={"inline"}
                        size={"small"}
                        color={"gray"}
                        onClick={() => {
                            history.push(VIEW_SENT_EST);
                        }}
                    >
                        더 보기
                    </Text>
                }
            >
                <Box marginBottom={"8px"}>
                    <Card>
                        <Text weight={"bold"}>
                            카닥 견적 번호 : <Text type={"inline"}>{reqid}</Text>
                        </Text>
                    </Card>
                </Box>
                {accReqNo && (
                    <Card>
                        <Text weight={"bold"}>
                            {insuName} 사고 번호 : <Text type={"inline"}>{accReqNo}</Text>
                        </Text>
                    </Card>
                )}
            </TableView>

            <TableView title={`받은 수리 견적서 - ${receiveEstCnt}건`}>
                {estList.map(est => {
                    const {
                        shop: { callmentnumber },
                        shop = {},
                        price = [],
                        estid,
                        statuscode
                    } = est;

                    const statusCode = Number(statuscode);
                    // 견적서 리스트에서 500 이상의 상태코드가 1개 이상 존재하면서, 견적서의(자기 자신)의 상태코드가 500보다 작으면, 해당 영역을 표시하지 않는다.
                    const isShowBottomFlexBox = !(isReservationSuccessCode && statusCode < 500);

                    return (
                        <Box key={estid} marginBottom={16}>
                            <Card>
                                <Title
                                    more={"상세견적"}
                                    arrow
                                    onClick={() => {
                                        history.push(`${VIEW_SHOP}?shopid=${shop.shopid}`);
                                    }}
                                    onMoreClick={() => {
                                        history.push(`${VIEW_SHOP_EST}?estid=${estid}`);
                                    }}
                                >
                                    {shop.shopname}
                                </Title>
                                <Text
                                    size={"small"}
                                    color={"gray700"}
                                    list={[
                                        `${shop.area.depth1} ${shop.area.depth2}`,
                                        `수리 만족도 ${stringToFloat(shop.avg_satisfaction, 2)}% · 후기 ${
                                            shop.reviewcnt
                                        }건`
                                    ]}
                                />

                                <Divider forText />

                                {price.length ? (
                                    <>
                                        {price.map((p, idx) => {
                                            const uniqueKey = `${p.cost}-${idx}`;

                                            return (
                                                <Fragment key={uniqueKey}>
                                                    {statusCode !== 200 && p.type === "cash" && (
                                                        <FlexBox spaceBetween>
                                                            <Text>비보험 수리</Text>
                                                            <Text color={"blue"} weight={"bold"}>
                                                                {applyCurrency(p.cost)}원
                                                            </Text>
                                                        </FlexBox>
                                                    )}

                                                    {statusCode !== 200 && p.type === "insurance" && (
                                                        <Box marginTop={8} marginBottom={15}>
                                                            <FlexBox spaceBetween>
                                                                <Text>
                                                                    보험 수리{" "}
                                                                    {!!p.deductible && (
                                                                        <Text
                                                                            type={"inline"}
                                                                            size={"smaller"}
                                                                            color={"gray"}
                                                                        >
                                                                            (자기 부담금 포함)
                                                                        </Text>
                                                                    )}
                                                                </Text>

                                                                <Text color={"blue"} weight={"bold"}>
                                                                    {applyCurrency(p.cost)}원
                                                                </Text>
                                                            </FlexBox>

                                                            <Text size={"small"} color={"gray700"} align={"right"}>
                                                                *자기부담금 {applyCurrency(p.deductible)}원
                                                            </Text>
                                                        </Box>
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                                        {isShowBottomFlexBox && (
                                            <FlexBox row marginTop={16}>
                                                {statusCode !== 200 && (
                                                    <>
                                                        <Button
                                                            color={"outlined"}
                                                            // eslint-disable-next-line consistent-return
                                                            onClick={() => {
                                                                if (statusCode === 550 || statusCode === 600) {
                                                                    const { code_name: cn, release_date: rd } =
                                                                        est.pay_statement;

                                                                    setSelectedBookedData({
                                                                        paid: cn,
                                                                        releaseDate: rd
                                                                            .split(" ")[0]
                                                                            .replace(/-/g, "."),
                                                                        price: est.price.find(({ type }) => {
                                                                            return type === est.pay_statement.code_name;
                                                                        }),
                                                                        ...shop
                                                                    });
                                                                    setIsShownBookedModal(true);

                                                                    return;
                                                                }

                                                                history.push(`${BOOKING}?estid=${estid}`);
                                                            }}
                                                        >
                                                            {BTN_BOOKING[statusCode]}
                                                        </Button>

                                                        <Box paddingX={8} />
                                                    </>
                                                )}

                                                <Button
                                                    color={"night"}
                                                    onClick={() => {
                                                        phoneCall(callmentnumber);
                                                    }}
                                                >
                                                    전화 상담하기
                                                </Button>
                                            </FlexBox>
                                        )}
                                    </>
                                ) : (
                                    <NotYet />
                                )}
                            </Card>
                        </Box>
                    );
                })}
            </TableView>

            <Divider type={"dashed"} />

            <Box marginTop={"16px"}>
                <Card>
                    <Title>카닥과 상담이 필요하신가요?</Title>

                    <Text
                        size={"small"}
                        color={"gray700"}
                        list={[
                            "고객센터 운영시간 - 평일 09:00 ~ 18:00",
                            "(점심시간 12:30 ~ 13:30, 주말 및 공휴일 휴무)"
                        ]}
                    />

                    <Divider forText />

                    <Button
                        color={"outlined"}
                        onClick={() => {
                            phoneCall(tel);
                        }}
                    >
                        전화 상담하기
                    </Button>
                </Card>
            </Box>

            <Modal
                isShown={isShownBookedModal}
                padding={16}
                title={
                    <FlexBox marginBottom={16} width={"100%"}>
                        <Strong size={"big"}>{selectedBookedData.shopname}</Strong>
                    </FlexBox>
                }
                content={
                    <Box width={"100%"}>
                        <FlexBox row spaceBetween marginBottom={4}>
                            <Paragraph color={"gray700"} flexBasis={132}>
                                출고일자
                            </Paragraph>

                            <Box textAlign={"right"}>
                                <Paragraph>{selectedBookedData.releaseDate}</Paragraph>
                            </Box>
                        </FlexBox>

                        <FlexBox row spaceBetween marginBottom={4}>
                            <Paragraph color={"gray700"} flexBasis={132}>
                                업체 주소
                            </Paragraph>

                            <Box textAlign={"right"}>
                                <Paragraph wordBreak={"keep-all"}>{selectedBookedData.corp_address}</Paragraph>
                            </Box>
                        </FlexBox>

                        <FlexBox row spaceBetween marginBottom={4}>
                            <Paragraph color={"gray700"} flexBasis={132}>
                                업체전화번호
                            </Paragraph>

                            <Box textAlign={"right"}>
                                <Paragraph>{selectedBookedData.callmentnumber}</Paragraph>
                            </Box>
                        </FlexBox>

                        <FlexBox row spaceBetween>
                            <Paragraph color={"gray700"} flexBasis={132}>
                                견적금액 ({INSURANCE_TEXT[selectedBookedData.paid]})
                            </Paragraph>

                            <FlexBox row>
                                <Text color={"blue"}>{applyCurrency(selectedBookedData.price.cost)}</Text>
                                <Text>원</Text>
                            </FlexBox>
                        </FlexBox>
                    </Box>
                }
                buttons={
                    <Box marginTop={32} marginRight={8} flex={1}>
                        <Button
                            color={"night"}
                            onClick={() => {
                                setIsShownBookedModal(false);
                            }}
                        >
                            확인
                        </Button>
                    </Box>
                }
                isDivider={false}
            />
        </Screen>
    );
}

ViewReceived.propTypes = {
    setNavToolbar: PropTypes.func.isRequired,
    tel: PropTypes.string.isRequired,
    parseQueries: PropTypes.func,
    getSentEstMetadata: PropTypes.func,
    getEstByShop: PropTypes.func,
    reqid: PropTypes.string,
    accReqNo: PropTypes.string,
    insuName: PropTypes.string,
    estList: PropTypes.arrayOf(
        PropTypes.shape({
            price: PropTypes.arrayOf(
                PropTypes.shape({
                    type: PropTypes.string.isRequired,
                    cost: PropTypes.number.isRequired,
                    deductible: PropTypes.number
                })
            ),
            shop: PropTypes.shape({
                shopname: PropTypes.string.isRequired,
                avg_satisfaction: PropTypes.string.isRequired,
                reviewcnt: PropTypes.string.isRequired,
                area: PropTypes.shape({
                    depth1: PropTypes.string.isRequired,
                    depth2: PropTypes.string.isRequired
                }).isRequired
            }).isRequired,
            statuscode: PropTypes.string.isRequired
        })
    ),
    promotioncode: PropTypes.string
};

ViewReceived.defaultProps = {
    estList: [],
    parseQueries: identity,
    getSentEstMetadata: identity,
    getEstByShop: identity,
    reqid: "",
    accReqNo: "",
    insuName: "",
    promotioncode: ""
};

export default withErrorBoundary(ViewReceived, {
    fallback: "an error has occurred"
});
