import { applyMiddleware } from "redux";
import { compose, identity } from "ramda";
import { composeWithDevTools } from "redux-devtools-extension";

function createComposeMiddleware(mode = "development") {
    const devToolsWrapper = mode === "development" ? composeWithDevTools : identity;

    return compose(devToolsWrapper, applyMiddleware);
}

export default createComposeMiddleware;
