import { connect } from "react-redux";
import { ViewSentEst } from "~/pages";

function mapStateToProps({ received: { sentEst } }) {
    return {
        sentEst
    };
}

export default connect(mapStateToProps)(ViewSentEst);
