import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "ui-box";
import { useHistory } from "react-router-dom";
import useReduxForm from "use-redux-form";
import { withErrorBoundary } from "@sentry/react";
import { convertDateFormatAsDB, resolveDateString } from "~/packages/utils";
import {
    Button,
    DateSelection,
    Divider,
    FlexBox,
    FloatingContent,
    Gap,
    Modal,
    Paragraph,
    Partial,
    Portal,
    Screen,
    Strong,
    Text,
    TextInputField,
    TimeSelection,
    Title
} from "~/packages/ui/mobile";

import { ImgProgress, Location16, Phone16 } from "~/packages/ui/svg";

const modalRoot = document.getElementById("modal-root");
const div = document.createElement("div");

const BOOKING_BTN_TEXT = {
    300: "예약하기",
    400: "예약하기",
    450: "예약변경",
    500: "예약변경",
    550: "예약내역"
};

function Booking({
    reqid,
    shop,
    statusCode,
    bookdttm,
    bookInitDate,
    isEditable,
    thirdParty,
    get3rdParty,
    setNavToolbar,
    parseQueries,
    getShopEst,
    updateBookingInfoField,
    requestBooking,
    updateBooking,
    cancelBooking
}) {
    const history = useHistory();
    const bookingDate = bookdttm ? new Date(resolveDateString(bookdttm)) : new Date();
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [isShownConfirmModal, setIsShownConfirmModal] = useState(false);
    const [isShownCancelModal, setIsShownCancelModal] = useState(false);
    const [isTimeSelectionShown, setTimeSelectionShown] = useState(false);
    const { isValidated, getFieldProps, handleSubmit } = useReduxForm({
        storePath: "booking.form",

        validate: values => {
            const errors = {};

            if (!values.estid) {
                errors.estid = "isRequired";
            }

            if (!values.bookdttm) {
                errors.bookdttm = "isRequired";
            }

            if (!values.bookusername) {
                errors.bookusername = "isRequired";
            }

            if (!values.bookmobile) {
                errors.bookmobile = "isRequired";
            }

            return errors;
        },

        onSubmit: ({ isInvalid }) => {
            if (!isInvalid) {
                switch (statusCode) {
                    case 300:
                    case 400: {
                        requestBooking();
                        setIsShownConfirmModal(true);

                        break;
                    }

                    case 450:
                    case 500: {
                        updateBooking();
                        history.push(`view-received?reqid=${reqid}`);

                        break;
                    }

                    default: {
                        break;
                    }
                }
            }
        },

        onChange: updateBookingInfoField
    });

    useEffect(() => {
        const init = async () => {
            get3rdParty();
            parseQueries(window.location.search);
            await getShopEst();
        };

        init();

        setNavToolbar({
            title: "예약하기",
            left: "back"
        });
    }, [parseQueries, get3rdParty, getShopEst, setNavToolbar]);

    const handleConfirm = ({ meridiem, hour, minute }) => {
        let hour24 = hour;

        if (["오후", "pm", "PM"].includes(meridiem)) {
            hour24 = hour + 12;
        }

        const year = bookingDate.getFullYear();
        const month = bookingDate.getMonth();
        const date = bookingDate.getDate();

        const nextBookingDate = new Date(year, month, date, hour24, minute, 0);

        updateBookingInfoField({
            name: "bookdttm",
            value: convertDateFormatAsDB(nextBookingDate)
        });
        setTimeSelectionShown(false);
    };

    if (!shop) {
        return "";
    }

    const { corp_address: address, callmentnumber } = shop;

    return (
        <>
            <Screen.Next hasNavi stretch>
                <Partial backgroundColor={"#fff"}>
                    <Title
                        render={() => (
                            <Text tag={"h2"} size={"18px"} weight={"bold"}>
                                {shop.shopname}
                            </Text>
                        )}
                    />

                    <Paragraph color={"gray700"} size={"small"}>
                        <FlexBox alignItems={"center"} row>
                            <Location16 /> <Box marginLeft={4}>{address}</Box>
                        </FlexBox>
                    </Paragraph>

                    <Paragraph color={"gray700"} size={"small"}>
                        <FlexBox alignItems={"center"} row>
                            <Phone16 /> <Box marginLeft={4}>{callmentnumber}</Box>
                        </FlexBox>
                    </Paragraph>
                </Partial>

                <Divider />

                <Partial backgroundColor={"#fff"} paddingTop={16}>
                    <DateSelection
                        initialDate={bookInitDate ? new Date(resolveDateString(bookInitDate)) : new Date()}
                        selectedDate={bookingDate}
                        onClick={item => () => {
                            updateBookingInfoField({
                                name: "bookdttm",
                                value: convertDateFormatAsDB(item.date)
                            });
                        }}
                        isEditable={isEditable}
                    />
                </Partial>

                <Divider />

                <Partial backgroundColor={"#fff"} paddingTop={16}>
                    <TimeSelection
                        selectedDate={bookingDate}
                        onConfirm={handleConfirm}
                        isShown={isTimeSelectionShown}
                        setIsShown={setTimeSelectionShown}
                        isEditable={isEditable}
                    />
                </Partial>

                <Gap />

                <Partial is={"form"} backgroundColor={"#fff"}>
                    <TextInputField
                        label={"예약자명"}
                        placeholder={"예약자명을 입력해주세요"}
                        {...getFieldProps("bookusername", {
                            exclude: ["isInvalid"]
                        })}
                        isRequired
                    />

                    <TextInputField
                        label={"연락처"}
                        placeholder={"연락처를 입력해주세요"}
                        {...getFieldProps("bookmobile", {
                            exclude: ["isInvalid"]
                        })}
                        isRequired
                    />

                    <TextInputField
                        label={"픽업장소"}
                        placeholder={"픽업장소를 입력해주세요"}
                        {...getFieldProps("pickuparea", {
                            exclude: ["isInvalid"]
                        })}
                    />

                    <Box color={"#999"} marginTop={4} fontSize={12}>
                        <Box>
                            예약 및 커뮤니케이션을 위한{" "}
                            <Text
                                type={"inline"}
                                size={"smaller"}
                                color={"gray"}
                                onClick={() => {
                                    setTitle("개인정보 제3자 제공");
                                    setContent(thirdParty);
                                }}
                                style={{
                                    textDecoration: "underline"
                                }}
                            >
                                개인정보 제3자 제공
                            </Text>
                            에 동의합니다.
                        </Box>

                        <Box>
                            제공받는 대상 :{" "}
                            <Text type={"inline"} size={"smaller"} color={"gray"}>
                                {shop.shopname}
                            </Text>
                        </Box>
                    </Box>

                    <FlexBox marginTop={36} row>
                        {[450, 500].includes(statusCode) && (
                            <Box marginRight={8} flex={1}>
                                <Button
                                    color={"outlined"}
                                    onClick={() => {
                                        setIsShownCancelModal(true);
                                    }}
                                >
                                    예약취소
                                </Button>
                            </Box>
                        )}
                        <Box flex={1}>
                            <Button color={"night"} disabled={!isValidated} onClick={handleSubmit}>
                                {BOOKING_BTN_TEXT[statusCode]}
                            </Button>
                        </Box>
                    </FlexBox>
                </Partial>
            </Screen.Next>

            {content && (
                <Portal rootEl={modalRoot} el={div}>
                    <FloatingContent
                        title={title}
                        content={content}
                        onClose={() => {
                            setTitle("");
                            setContent("");
                        }}
                    />
                </Portal>
            )}

            <Modal
                isShown={isShownConfirmModal}
                title={
                    <>
                        <Strong size={"big"} marginBottom={16}>
                            예약 요청되었습니다.
                        </Strong>

                        <FlexBox width={220} alignItems={"center"} col>
                            <ImgProgress />

                            <FlexBox width={"100%"} marginTop={8} spaceBetween row>
                                <Box is={"span"} fontSize={14}>
                                    예약요청
                                </Box>
                                <Box is={"span"} fontSize={14}>
                                    확인중
                                </Box>
                                <Box is={"span"} fontSize={14} color={"#cecece"}>
                                    예약확정
                                </Box>
                            </FlexBox>
                        </FlexBox>
                    </>
                }
                content={
                    <>
                        <Paragraph marginBottom={8}>업체에서 확인 후 예약을 확정할 예정입니다.</Paragraph>
                        <Paragraph color={"#999"}>장시간 예약이 되지 않을 경우,</Paragraph>
                        <Paragraph color={"#999"}>업체에 문의해주세요.</Paragraph>
                    </>
                }
                buttons={
                    <Box marginTop={32} flex={1}>
                        <Button
                            color={"night"}
                            onClick={() => {
                                setIsShownConfirmModal(false);
                                history.push(`view-received?reqid=${reqid}`);
                            }}
                        >
                            확인
                        </Button>
                    </Box>
                }
                isDivider
            />

            <Modal
                isShown={isShownCancelModal}
                title={
                    <Strong size={"big"} marginBottom={32}>
                        예약을 취소하시겠습니까?
                    </Strong>
                }
                buttons={
                    <>
                        <Box marginRight={8} flex={1}>
                            <Button
                                color={"outlined"}
                                onClick={() => {
                                    setIsShownCancelModal(false);
                                }}
                            >
                                닫기
                            </Button>
                        </Box>

                        <Box flex={1}>
                            <Button
                                color={"night"}
                                onClick={() =>
                                    Promise.resolve(cancelBooking()).then(() => {
                                        setIsShownCancelModal(false);
                                        history.push(`view-received?reqid=${reqid}`);
                                    })
                                }
                            >
                                예약 취소
                            </Button>
                        </Box>
                    </>
                }
                isDivider={false}
            />
        </>
    );
}

Booking.propTypes = {
    get3rdParty: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    setNavToolbar: PropTypes.func.isRequired,
    parseQueries: PropTypes.func.isRequired,
    getShopEst: PropTypes.func.isRequired,
    updateBookingInfoField: PropTypes.func.isRequired,
    requestBooking: PropTypes.func.isRequired,
    updateBooking: PropTypes.func.isRequired,
    cancelBooking: PropTypes.func.isRequired,
    reqid: PropTypes.string,
    bookdttm: PropTypes.string,
    bookInitDate: PropTypes.string,
    estid: PropTypes.string,
    statusCode: PropTypes.number,
    shop: PropTypes.shape({
        shopname: PropTypes.string,
        corp_address: PropTypes.string,
        callmentnumber: PropTypes.string
    }),
    thirdParty: PropTypes.string
};

Booking.defaultProps = {
    reqid: "",
    bookdttm: "",
    bookInitDate: "",
    estid: "",
    statusCode: 200,
    shop: {},
    thirdParty: ""
};

export default withErrorBoundary(Booking, {
    fallback: "an error has occurred"
});
