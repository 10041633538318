import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import css from "./table-view.css";
import Text from "../Text";
import FlexBox from "../FlexBox";
import More from "../More";

function TableView({ title, more, children }) {
    return (
        <section className={cx(css.tableView)}>
            {title && (
                <FlexBox row spaceBetween>
                    <Text tag={"strong"} size={"small"} color={"gray700"} className={cx(css.tableViewTitle)}>
                        {title}
                    </Text>

                    {more && <More arrow>{more}</More>}
                </FlexBox>
            )}
            {children}
        </section>
    );
}

TableView.propTypes = {
    title: PropTypes.string,
    more: PropTypes.node,
    children: PropTypes.node
};

TableView.defaultProps = {
    title: "",
    more: "",
    children: ""
};

export default TableView;
