import * as types from "../actionTypes";

const initialState = {
    promotioncode: "",
    reqid: "",
    accReqNo: "",
    insuName: "",
    ownerName: "",
    plateNo: "",
    carBrand: "",
    car: "",
    year: "",
    carImages: [],
    carImageIds: [],
    carImageDesc: "",
    phoneNo: "",
    addressNameA: "",
    addressNameB: "",
    addressA: {},
    addressB: {},
    locationIds: [],
    reqRentCar: false,
    reqPickup: false,
    agreement: false,
    requestList: [],
    errors: {
        getCarInsuranceInfo: null,
        getImageIds: null,
        submitEstForm: null
    }
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.REQUEST_GET_CAR_INSURANCE_INFO: {
            return {
                ...state,
                accReqNo: "",
                insuName: "",
                errors: {
                    ...state.errors,
                    getCarInsuranceInfo: null
                }
            };
        }

        case types.FAILURE_GET_CAR_INSURANCE_INFO: {
            return {
                ...state,
                accReqNo: "",
                insuName: "",
                errors: {
                    ...state.errors,
                    getCarInsuranceInfo: payload
                }
            };
        }

        case types.SUCCESS_GET_CAR_INSURANCE_INFO: {
            return {
                ...state,
                accReqNo: payload.accReqNo,
                insuName: payload.insuName,
                insurerName: payload.insuName ? `${payload.insuName} : ${payload.accReqNo}` : "",
                requestList: payload.requestList,
                errors: {
                    ...state.errors,
                    getCarInsuranceInfo: null
                }
            };
        }

        case types.SAVE_EST_FIELDS_STEP1: {
            return {
                ...state,
                [payload.name]: payload.value
            };
        }

        case types.SAVE_EST_FIELDS_STEP2: {
            return {
                ...state,
                carImages: payload.carImages,
                carImageDesc: payload.carImageDesc,
                phoneNo: payload.phoneNo,
                addressNameA: payload.addressNameA,
                addressNameB: payload.addressNameB,
                reqRentCar: payload.reqRentCar,
                reqPickup: payload.reqPickup,
                agreement: payload.agreement
            };
        }

        case types.REQUEST_GET_IMAGE_IDS: {
            return {
                ...state,
                carImageIds: [],
                errors: {
                    ...state.errors,
                    getImageIds: null
                }
            };
        }

        case types.FAILURE_GET_IMAGE_IDS: {
            return {
                ...state,
                carImageIds: [],
                errors: {
                    ...state.errors,
                    getImageIds: payload
                }
            };
        }

        case types.SUCCESS_GET_IMAGE_IDS: {
            return {
                ...state,
                carImageIds: payload,
                errors: {
                    ...state.errors,
                    getImageIds: null
                }
            };
        }

        case types.REQUEST_GET_LOCATION_IDS: {
            return {
                ...state,
                locationIds: [],
                errors: {
                    ...state.errors,
                    getLocationIds: null
                }
            };
        }

        case types.FAILURE_GET_LOCATION_IDS: {
            return {
                ...state,
                locationIds: [],
                errors: {
                    ...state.errors,
                    getLocationIds: payload
                }
            };
        }

        case types.SUCCESS_GET_LOCATION_IDS: {
            return {
                ...state,
                locationIds: payload,
                errors: {
                    ...state.errors,
                    getLocationIds: null
                }
            };
        }

        case types.REQUEST_SUBMIT_EST_FORM: {
            return {
                ...state,
                reqid: "",
                errors: {
                    ...state.errors,
                    submitEstForm: null
                }
            };
        }

        case types.FAILURE_SUBMIT_EST_FORM: {
            return {
                ...state,
                reqid: "",
                errors: {
                    ...state.errors,
                    submitEstForm: payload
                }
            };
        }

        case types.SUCCESS_SUBMIT_EST_FORM: {
            return {
                ...state,
                reqid: payload,
                errors: {
                    ...state.errors,
                    submitEstForm: null
                }
            };
        }

        case types.SAVE_BRAND_NAME: {
            return {
                ...state,
                carBrand: payload
            };
        }

        case types.SAVE_ADDRESS: {
            return {
                ...state,
                [payload.name]: payload.value
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
