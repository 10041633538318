import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { applyCurrency } from "~/packages/utils";
import { Text, Wrap, FlexBox, Divider } from "~/packages/ui/mobile";

const TITLE_MAP = {
    repair: "수리비",
    part: "부품가격",
    option: "옵션"
};

const ITEM_MAP = {
    repair: ["part", "solution"],
    part: "part_etc",
    option: "option_description"
};

function RepairPrice({ type, price, period, periodUnit }) {
    const { repair = [], part = [], option = [], priceMap = {} } = price;
    const repairParts = { repair, part, option };
    const keys = Object.keys(repairParts);

    return (
        <Wrap padding={"20px"} backgroundColor={"#fff"}>
            {keys.map(key => {
                const val = repairParts[key];

                if (val.length === 0) {
                    return "";
                }

                return (
                    <Fragment key={key}>
                        <Wrap padding={"16px 0"}>
                            <FlexBox spaceBetween>
                                <Text size={"big"} color={"black"}>
                                    {TITLE_MAP[key]}
                                </Text>

                                <Text size={"big"} color={"black"}>
                                    {applyCurrency(priceMap[key])}원
                                </Text>
                            </FlexBox>

                            {val.map((typedItem, idx) => {
                                const uniqueKey = `${key}-${idx}`;
                                const { qty } = typedItem;
                                const qtyPrice = typedItem.price * parseInt(qty.trim() || "1", 10);

                                return (
                                    <FlexBox key={uniqueKey} spaceBetween>
                                        <Text size={"normal"} color={"gray700"}>
                                            {[ITEM_MAP[key]]
                                                .flat()
                                                .map(k => typedItem[k])
                                                .join(" ")}
                                        </Text>

                                        <Text size={"normal"} color={"gray700"}>
                                            {applyCurrency(qtyPrice)}원
                                        </Text>
                                    </FlexBox>
                                );
                            })}
                        </Wrap>

                        <Divider />
                    </Fragment>
                );
            })}

            <Wrap padding={"24px 0"}>
                <Text color={"black"}>예상수리기간 - 입고일로부터 {`${period}${periodUnit}`}</Text>

                {type === "insurance" && (
                    <Text size={"small"} color={"gray700"}>
                        보험 수리 견적은 실제 보험 청구액과 다를 수 있습니다.
                    </Text>
                )}
            </Wrap>

            <Divider />

            <Wrap padding={"16px 0"}>
                <FlexBox spaceBetween>
                    <Text size={"big"} weight={"bold"}>
                        합계 (VAT 포함)
                    </Text>

                    <Text className={"t-txt-clr-blue"} size={"big"} weight={"bold"}>
                        {applyCurrency(price.cost)}원
                    </Text>
                </FlexBox>

                {type === "insurance" && (
                    <Text size={"small"} color={"gray700"} align={"right"}>
                        *자기부담금 {applyCurrency(price.deductible)}원 포함
                    </Text>
                )}
            </Wrap>
        </Wrap>
    );
}

RepairPrice.propTypes = {
    type: PropTypes.string,
    price: PropTypes.shape({
        repair: PropTypes.arrayOf(
            PropTypes.shape({
                part: PropTypes.string,
                solution: PropTypes.string,
                price: PropTypes.number
            })
        ),
        part: PropTypes.arrayOf(
            PropTypes.shape({
                part: PropTypes.string,
                solution: PropTypes.string,
                price: PropTypes.number,
                qty: PropTypes.string
            })
        ),
        option: PropTypes.arrayOf(
            PropTypes.shape({
                option_description: PropTypes.string,
                price: PropTypes.number
            })
        ),
        priceMap: PropTypes.shape({
            repair: PropTypes.number,
            part: PropTypes.number,
            option: PropTypes.number
        }),
        cost: PropTypes.number,
        deductible: PropTypes.number
    }),
    period: PropTypes.number,
    periodUnit: PropTypes.string
};

RepairPrice.defaultProps = {
    type: "",
    price: {},
    period: -1,
    periodUnit: ""
};

export default RepairPrice;
