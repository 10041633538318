import { connect } from "react-redux";
import { ReqEstDone } from "~/pages";

function mapStateToProps({ assets: { tel }, form: { reqid, accReqNo, insuName } }) {
    return {
        reqid,
        accReqNo,
        insuName,
        tel
    };
}

export default connect(mapStateToProps)(ReqEstDone);
