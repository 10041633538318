import axios from "axios";
import { identity } from "ramda";
import { genericError as axiosError } from "~/packages/utils";

function interceptors(config) {
    const { onSuccess = identity, onCancel = identity, onTimeout = identity, onError = identity } = config;

    return instance => {
        instance.interceptors.response.use(
            response => {
                const data = onSuccess(response);

                return data || response;
            },

            error => {
                if (axios.isCancel(error)) {
                    onCancel(error);

                    return Promise.reject(axiosError(error.message || "CANCEL", error));
                }

                // cause timeout
                if (error.code === "ECONNABORTED") {
                    return Promise.reject(onTimeout(error) || error);
                }

                return Promise.reject(onError(error) || error);
            }
        );

        return instance;
    };
}

export default interceptors;
