export const GET_BRAND_LIST = "/brand";
export const GET_ADDRESS_BY_WORD = "/address/search";
export const GET_CAR_INSURANCE_METADATA = "/estimationReqIns/promocode";
export const GET_ESTIMATE_METADATA = "/estimationReq/reqid";
export const GET_ESTIMATE_LIST = "/estimation/reqid";
export const GET_SHOP = "/shop";
export const GET_SHOP_EST = "/estimation";
export const GET_REVIEWS = "/shopReview/shop";
export const GET_PORTFOLIO = "/afterGallery/shopid";
export const POST_USERS = "/user";
export const POST_FILE_UPLOADS = "/fileObject";
export const POST_USER_LOCATIONS = "/userLocation";
export const POST_ESTIMATE_REQUESTS = "/estimationReq";
