import { connect } from "react-redux";
import { ViewShopEst } from "~/pages";
import { parseQueries, getShopEst } from "~/store/actions";

function mapStateToProps({
    received: {
        est: { price = [], shop },
        est
    }
}) {
    return {
        est,
        shop,
        price
    };
}

function mapDispatchToProps(dispatch) {
    return {
        parseQueries: (query = window.location.search) => dispatch(parseQueries(query)),
        getShopEst: () => dispatch(getShopEst())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewShopEst);
