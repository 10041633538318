import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import Box from "ui-box";
import { identity } from "ramda";
import css from "./text-input-field.css";
import Strong from "../Strong";
import useTheme from "../theme";

const TextInputField = props => {
    const {
        type,
        name,
        value,
        placeholder,
        label,
        readOnly,
        onChange,
        isRequired,
        indicator,
        maxLength,
        ...restProps
    } = props;

    const { borderRadius } = useTheme();
    const styleProps = {
        width: "100%",
        border: "1px solid #ededed",
        fontSize: 16,
        paddingX: 16,
        paddingY: 12,
        backgroundColor: "#fff",
        borderRadius
    };

    return (
        <Box marginY={12} className={css.textInputField} {...restProps}>
            {label && (
                <Strong size={"small"} color={"gray2"}>
                    {label}
                    {isRequired && "*"}
                </Strong>
            )}

            <Box position={"relative"} marginTop={8}>
                <Box
                    is={"input"}
                    type={type}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    onChange={onChange}
                    elevated={0}
                    maxLength={maxLength}
                    {...styleProps}
                />
                {indicator &&
                    cloneElement(indicator, {
                        style: {
                            position: "absolute",
                            top: "50%",
                            bottom: "50%",
                            right: 16,
                            transform: "translate(-50%, -50%)"
                        }
                    })}
            </Box>
        </Box>
    );
};

TextInputField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.node,
    readOnly: PropTypes.bool,
    isRequired: PropTypes.bool,
    indicator: PropTypes.node,
    maxLength: PropTypes.string,
    onChange: PropTypes.func
};

TextInputField.defaultProps = {
    type: "text",
    name: "",
    value: "",
    placeholder: "",
    label: "",
    readOnly: false,
    isRequired: false,
    onChange: identity,
    indicator: null,
    maxLength: ""
};

export default TextInputField;
