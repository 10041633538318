import React from "react";
import PropTypes from "prop-types";

function IcoClose({ dark }) {
    return (
        <svg xmlns={"http://www.w3.org/2000/svg"} width={"20"} height={"20"} viewBox={"0 0 20 20"}>
            <g fill={"none"} fillRule={"evenodd"} strokeLinecap={"round"} strokeLinejoin={"round"}>
                <g stroke={dark ? "#fff" : "#000"} strokeWidth={"2"}>
                    <g>
                        <g>
                            <path d={"M16 0L8 8 16 16"} transform={"translate(-20 -64) translate(22 66)"} />
                            <path
                                d={"M8 0L0 8 8 16"}
                                transform={"translate(-20 -64) translate(22 66) matrix(-1 0 0 1 8 0)"}
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

IcoClose.propTypes = {
    dark: PropTypes.bool
};

IcoClose.defaultProps = {
    dark: false
};

export default IcoClose;
