import React, { Children, cloneElement } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { identity } from "ramda";
import css from "./tabs.css";
import FlexBox from "../FlexBox";
import Button from "../Button";

function Tabs({ children, currentTab, onClick }) {
    // `child` === <Tab />
    return (
        <FlexBox className={cx(css.tabs)} row>
            {Children.map(children, (child, idx) => (
                <Button
                    className={cx(css.tabsBtn, {
                        "is-selected": currentTab === idx
                    })}
                    onClick={evt => onClick(idx, evt)}
                    base
                >
                    {cloneElement(child, {
                        selected: currentTab === idx
                    })}
                </Button>
            ))}
        </FlexBox>
    );
}

Tabs.propTypes = {
    children: PropTypes.node.isRequired,
    currentTab: PropTypes.number,
    onClick: PropTypes.func
};

Tabs.defaultProps = {
    currentTab: 0,
    onClick: identity
};

export default Tabs;
