import React from "react";
import PropTypes from "prop-types";
import { identity } from "ramda";
import TextInputField from "../TextInputField";
import { GT } from "../../svg";

function SelectBox({ value, placeholder, label, isRequired, onClick }) {
    return (
        <TextInputField
            type={"text"}
            value={value}
            placeholder={placeholder}
            onClick={onClick}
            label={label}
            isRequired={isRequired}
            indicator={<GT onClick={onClick} />}
            readOnly
        />
    );
}

SelectBox.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.node,
    isRequired: PropTypes.bool,
    onClick: PropTypes.func
};

SelectBox.defaultProps = {
    value: "",
    placeholder: "",
    label: "",
    isRequired: false,
    onClick: identity
};

export default SelectBox;
