import qs from "query-string";
import { generateUserkey } from "~/utils";
import * as types from "../actionTypes";

export function parseQueries(queries) {
    const parsedQueries = qs.parse(queries);

    return {
        type: types.SAVE_PARSED_QUERIED,
        payload: {
            ...parsedQueries
        }
    };
}

export function getJumbotronData() {
    return async (dispatch, getState, api) => {
        dispatch({
            type: types.REQUEST_JUMBOTRON_DATA
        });

        try {
            const { result } = await api.getJumbotronData();

            dispatch({
                type: types.SUCCESS_JUMBOTRON_DATA,
                payload: result
            });
        } catch (err) {
            dispatch({
                type: types.FAILURE_JUMBOTRON_DATA,
                payload: err
            });
        }
    };
}

// TODO use persist storage module
export function saveUserkey(key = "userkey") {
    return (dispatch, getState, api) => {
        let {
            bootstrap: { userkey }
        } = getState();

        if (!userkey) {
            try {
                userkey = window.localStorage.getItem(key);

                if (!userkey) {
                    dispatch({
                        type: types.GENERATE_USERKEY
                    });

                    userkey = generateUserkey();

                    window.localStorage.setItem(key, userkey);
                }
            } catch (err) {
                dispatch({
                    type: types.USERKEY_ERROR,
                    payload: err
                });
            }
        }

        // TODO no error handle for now
        api.createUser(userkey);

        dispatch({
            type: types.SAVE_USERKEY,
            payload: userkey
        });
    };
}
