import React, { memo } from "react";
import PropTypes from "prop-types";
import Box from "ui-box";
import useTheme, { Partial, Strong, Paragraph, FlexBox, Star } from "~/packages/ui/mobile";
import { IcoMale, IcoFemale } from "~/packages/ui/svg";
import css from "./jumbotron.css";

const GENDER_MAP = {
    male: IcoMale,
    female: IcoFemale
};

function Jumbotron({ topBanner, reviews }) {
    const theme = useTheme();

    if (!topBanner.title) {
        return "";
    }

    const [line1, line2] = topBanner.title.split("\n");

    return (
        <Box
            position={"relative"}
            backgroundColor={theme.color.white}
            height={24 + 247 + 146}
            className={css.jumbotron}
        >
            <Partial backgroundColor={theme.color.black} color={theme.color.white} height={200}>
                <Box marginBottom={8}>
                    <Strong color={"inherit"} size={"biggest"} display={"inline-block"}>
                        {line1}
                    </Strong>

                    <Strong color={"inherit"} size={"biggest"} display={"inline-block"}>
                        {line2}
                    </Strong>
                </Box>

                <Box>
                    <Paragraph color={"inherit"}>{topBanner.subtitle}</Paragraph>
                </Box>
            </Partial>

            <Box paddingX={5} marginTop={-54} position={"absolute"} right={0} bottom={0} left={0}>
                <FlexBox
                    is={"ol"}
                    backgroundColor={"transparent"}
                    paddingBottom={24}
                    display={"flex"}
                    className={css.carousel}
                >
                    {reviews.map(review => {
                        // TODO ask id here
                        const { age, model, title, message, gender, rate } = review;

                        return (
                            <Box
                                key={message}
                                is={"li"}
                                height={247}
                                marginX={5}
                                padding={16}
                                paddingTop={12}
                                backgroundColor={theme.color.white}
                                boxShadow={theme.boxShadow}
                                borderRadius={theme.borderRadius}
                                position={"relative"}
                                flex={"0 0 323px"}
                                tabIndex={"0"}
                                className={css.carouselItem}
                            >
                                <FlexBox flexDirection={"column"} justifyContent={"space-between"} height={"100%"}>
                                    <Box flex={1}>
                                        <FlexBox flexDirection={"row"}>
                                            <Box is={GENDER_MAP[gender.toLowerCase()]} marginRight={8} />
                                            <Strong size={"big"}>{`${model} · ${age}세`}</Strong>
                                        </FlexBox>

                                        <Paragraph marginTop={8}>{title}</Paragraph>

                                        <Paragraph marginTop={8} color={"gray700"}>
                                            {message}
                                        </Paragraph>
                                    </Box>

                                    <FlexBox flexDirection={"row"} marginTop={8}>
                                        {[...Array(rate).keys()].map(key => {
                                            return <Star key={key} marginRight={4} />;
                                        })}
                                    </FlexBox>
                                </FlexBox>
                            </Box>
                        );
                    })}
                </FlexBox>
            </Box>
        </Box>
    );
}

Jumbotron.propTypes = {
    topBanner: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string
    }),
    reviews: PropTypes.arrayOf(
        PropTypes.shape({
            age: PropTypes.number,
            gender: PropTypes.oneOf(["Male", "Female"]),
            message: PropTypes.string,
            model: PropTypes.string,
            rate: PropTypes.number,
            title: PropTypes.string
        })
    )
};

Jumbotron.defaultProps = {
    topBanner: {},
    reviews: []
};

export default memo(Jumbotron);
