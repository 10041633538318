import { is, curry } from "ramda";

function remove(key, o) {
    let clone;

    if (is(Array, o)) {
        clone = [...o];
        clone.splice(key, 1);
    } else if (is(Object, o)) {
        clone = { ...o };
        const { [key]: val, ...restO } = clone;

        clone = restO;
    }

    return clone;
}

export default curry(remove);
