import { useCallback, useEffect, useState } from "react";

const useIntersect = (onIntersect, option, callBack) => {
    const [ref, setRef] = useState(null);

    const checkIntersect = useCallback(([entry], observer) => {
        if (entry.isIntersecting) {
            onIntersect(entry, observer);
        }
    }, []);

    useEffect(() => {
        let observer;

        if (ref) {
            observer = new IntersectionObserver(checkIntersect, option);

            if (typeof callBack === "function") {
                callBack({ ref, observer });
            } else {
                observer.observe(ref);
            }
        }

        return () => observer && observer.disconnect();
    }, [ref, option.root, option.threshold, option.rootMargin, checkIntersect]);

    return [ref, setRef];
};

export default useIntersect;
