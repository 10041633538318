import React from "react";
import PropTypes from "prop-types";
import Box from "ui-box";
import cx from "classnames";
import css from "./screen.css";
import useTheme from "../theme";

function Screen({ hasNavi, children, stretch }) {
    return (
        <>
            {hasNavi}

            <main
                className={cx(css.screen, {
                    [css.screenNavi]: hasNavi,
                    [css.screenStretch]: stretch
                })}
            >
                {children}
            </main>
        </>
    );
}

Screen.propTypes = {
    children: PropTypes.node,
    hasNavi: PropTypes.bool,
    stretch: PropTypes.bool
};

Screen.defaultProps = {
    children: "",
    hasNavi: false,
    stretch: false
};

// TODO use this as <Screen /> component later
Screen.Next = ({ hasNavi, children, stretch }) => {
    const theme = useTheme();
    const padding = stretch
        ? {
              padding: 0
          }
        : {
              paddingX: 20,
              paddingY: 24
          };

    const styleProps = {
        marginTop: hasNavi ? theme.height.navi : 0,
        backgroundColor: "#f7f7f7",
        ...padding
    };

    return (
        <>
            {hasNavi}

            <Box is={"main"} {...styleProps}>
                {children}
            </Box>
        </>
    );
};

Screen.Next.propTypes = {
    children: PropTypes.node,
    hasNavi: PropTypes.bool,
    stretch: PropTypes.bool
};

Screen.Next.defaultProps = {
    children: "",
    hasNavi: false,
    stretch: false
};

export default Screen;
