import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import css from "./grid.css";

function Grid({ rows, cols, gap, justifyContent, className, style, children }) {
    const nextStyle = {
        justifyContent,
        gridGap: gap,
        gridTemplateRows: rows,
        gridTemplateColumns: cols,
        ...style
    };

    return (
        <div className={cx(css.grid, className)} style={nextStyle}>
            {children}
        </div>
    );
}

Grid.propTypes = {
    children: PropTypes.node.isRequired,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rows: PropTypes.string,
    cols: PropTypes.string,
    justifyContent: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.shape({})
};

Grid.defaultProps = {
    gap: 0,
    rows: "",
    cols: "",
    justifyContent: "center",
    className: "",
    style: {}
};

export default Grid;
